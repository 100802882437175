import { Box, Container, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import axios from "axios";

export default function Footer() {
    const theme = useTheme();
    const [config, setConfig] = useState(null);

    useEffect(() => {
        axios
            .get(`/api/system-config`)
            .then((response) => {
                setConfig(response.data.data);
            })
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: `${theme.palette.background.main} !important`,
                mt: "1rem",
            }}
        >
            <Container>
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "40px",
                        }}
                    >
                        <img src={config?.DarkLogo} style={{ minWidth: '250px', maxWidth: '300px', maxHeight: '150px' }} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
