import { useRef, useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputLabel,
    TextField,
} from "@mui/material";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

import trLocale from "date-fns/locale/tr";
import ValidateEmail from "../../utils/validateEmail";
import ShowToast from "../ShowToast";
import InquiryTable from "./InquiryTable";
import getOnlyDate from "../../utils/getOnlyDate";

const ApplicationInquiryDialog = ({ open, handleClose, classes }) => {
    // Email
    const [Email, setEmail] = useState("");
    // BORN DATE
    const [bornDateValue, setBornDateValue] = useState(null);
    const [inquirySubmit, setInquirySubmit] = useState(false);
    const handleChangeBorn = (newValue) => {
        setBornDateValue(newValue);
    };
    const recaptchaRef = useRef();
    const [applicationData, setApplicationData] = useState(null);

    const onSubmit = () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        let config = {
            headers: {
                recaptcha: recaptchaValue,
            },
        };

        const data = {
            Email,
            BirthDate: getOnlyDate(bornDateValue),
        };

        if (!recaptchaValue)
            return ShowToast("Robot doğrulamayı tamamlamadınız!", {
                type: "error",
            });

        if (ValidateEmail(Email))
            axios
                .post("/common/application-form/user/check", data, config)
                .then((response) => {
                    ShowToast("Başvuru sorgulama başarılı!", {
                        type: "success",
                    });
                    setInquirySubmit(true);
                    setApplicationData(response.data.data);
                })
                .catch((error) => {
                    if (
                        error.response.data.message ==
                        "Robot doğrulama geçersiz"
                    )
                        recaptchaRef.current?.reset();

                    ShowToast(
                        error.response.data.message ??
                        "Başvurunuz sırasında bir hata oluştu!",
                        {
                            type: "error",
                        }
                    );
                });
        else
            ShowToast("Lütfen geçerli bir e-posta adresi giriniz.", {
                type: "error",
            });
    };

    return (
        <Dialog
            open={open}
            fullWidth
            onClose={handleClose}
            sx={{
                "& .MuiDialog-paper": {
                    borderRadius: "6px !important",
                },
            }}
        >
            <DialogTitle>Başvuru Sorgulama Ekranı</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Daha önce yapmış olduğunuz başvuruyu bu ekran üzerinden
                    sorgulayabilirsiniz.
                </DialogContentText>

                <Grid
                    container
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                        gap: "1.25rem",
                    }}
                >
                    <Grid item xs={12} sm={12}>
                        <InputLabel
                            sx={{
                                ml: "0.5rem",
                                mb: "0.25rem",
                                fontSize: "0.82rem",
                            }}
                            htmlFor="email-inquiry"
                        >
                            E-Posta Adresi
                        </InputLabel>
                        <TextField
                            className={classes.MuiInputBaseRoot}
                            required
                            id="email-inquiry"
                            // label="E-Posta Adresi"
                            variant="filled"
                            fullWidth
                            value={Email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel
                            sx={{
                                ml: "0.5rem",
                                mb: "0.25rem",
                                fontSize: "0.82rem",
                            }}
                            htmlFor="birth-date-inquiry"
                        >
                            Doğum Tarihi
                        </InputLabel>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={trLocale}
                        >
                            <MobileDatePicker
                                // label="Doğum Tarihi"
                                value={bornDateValue}
                                onChange={handleChangeBorn}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="birth-date-inquiry"
                                        className={classes.MuiInputBaseRoot}
                                        required
                                        variant="filled"
                                        fullWidth
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ justifyContent: "center !important" }}
                    >
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LdVY1AfAAAAADz7Wfi90us82U2JJtEHFEtb8H47"
                            hl="tr"
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <InquiryTable
                        data={applicationData}
                        inquirySubmit={inquirySubmit}
                    />
                </Grid>
            </DialogContent>
            <DialogActions sx={{ p: "1.25rem" }}>
                <Button variant="contained" onClick={onSubmit}>
                    Başvuru Sorgula
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ApplicationInquiryDialog;
