import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";

export default function Navbar({ periodDetails }) {
    const [config, setConfig] = useState(null);

    useEffect(() => {
        axios
            .get(`/api/system-config`)
            .then((response) => {
                setConfig(response.data.data);
            })
    }, []);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="static"
                sx={{
                    backgroundColor: (theme) =>
                        `${theme.palette.background.main}`,
                    padding: "20px",
                }}
            >
                <Toolbar variant="regular">
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                    >
                        <img src={config?.DarkLogo} style={{ minWidth: '250px', maxWidth: '300px', maxHeight: '150px' }} />
                    </Typography>

                    <Typography variant="h4" color="inherit" component="div">
                        Başvuru{" "}
                        <Typography component="span">
                            {periodDetails?.Name
                                ? `(${periodDetails?.Name})`
                                : null}
                        </Typography>
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
