const getOnlyDate = (bornDateValue) => {
    const date = new Date(bornDateValue);
    const timezoneOffset = date.getTimezoneOffset();
    const utcDate = new Date(date.getTime() - timezoneOffset * 60 * 1000);
    const isoString = utcDate.toISOString();

    return isoString;
};

export default getOnlyDate;
