import { Box } from "@material-ui/core";
import { useState } from "react";
import Footer from "./components/Footer";
import ApplicationForm from "./components/ApplicationForm";
import Navbar from "./components/Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
    const [periodDetails, setPeriodDetails] = useState(null);

    return (
        <Box>
            <Navbar
                periodDetails={periodDetails}
                setPeriodDetails={setPeriodDetails}
            />
            <ToastContainer limit={3} />
            <ApplicationForm
                periodDetails={periodDetails}
                setPeriodDetails={setPeriodDetails}
            />
            <Footer />
        </Box>
    );
};

export default App;
