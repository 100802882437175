import { useState } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import BasicDialog from "../BasicDialog";
import KVKKKText from "../KVKKText";

const KVKKCheckbox = ({ kvkkChecked, setKvkkChecked }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleChange = (event) => {
        setKvkkChecked(event.target.checked);
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
            }}
        >
            <Checkbox
                checked={kvkkChecked}
                onChange={handleChange}
                color="primary"
                inputProps={{ "aria-label": "KVKK Checkbox" }}
                size="small"
            />
            <Typography
                variant="body2"
                color="text.primary"
                sx={{
                    fontSize: "0.875rem",
                    textDecoration: "underline",
                    mr: "3px",
                    cursor: "pointer",
                }}
                onClick={() => setOpenDialog(true)}
            >
                KVKK Aydınlatma metnini
            </Typography>
            <Typography
                variant="body2"
                color="text.primary"
                component="span"
                sx={{ fontSize: "0.875rem" }}
            >
                okudum, kabul ediyorum.*
            </Typography>
            <BasicDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                title="KVKK Aydınlatma Metni"
                content={<KVKKKText />}
                icon={<InfoIcon />}
            />
        </Box>
    );
};

export default KVKKCheckbox;
