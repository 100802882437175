import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const KVKKKText = () => {
    const [config, setConfig] = useState(null);

    useEffect(() => {
        axios
            .get(`/api/system-config`)
            .then((response) => {
                setConfig(response.data.data);
            })
    }, []);

    return (
        <>
            <p style={{ textAlign: "center" }}>
                <strong style={{ textTransform: "uppercase" }}>
                    {config?.Name}
                </strong>
                <br />
                <strong>
                    KİŞİSEL VERİLERİN KORUNMASI, İŞLENMESİ VE GİZLİLİK
                    POLİTİKASI
                </strong>
            </p>
            <p>
                1-Şirketimiz, {config?.Name} (“Şirket”), bu
                (“Politika”) ile tüm muhataplarının, kişisel verilerinin elde
                edilmesi, işlenmesi, silinmesi, yok edilmesi veya
                anonimleştirilmesinde tabi olduğu yükümlülükleri ile uyacağı
                usul ve esasları belirlemektedir.
            </p>
            <p>
                Bu doğrultuda kişisel veriler bakımından 6698 sayılı Kişisel
                Verilerin Korunması Kanunu (“Kanun”) kapsamında Çalışan
                Adayları, Müşteriler, Şirket Hissedarları, Şirket Yetkilileri,
                Ziyaretçiler, İşbirliği İçinde Olduğumuz Kurumların, Alt
                İşverenlerin ve Tedarikçilerin Çalışanları, Hissedarları ve
                Yetkilileri ile Üçüncü Kişiler başta olmak üzere (“Veri Sahibi”)
                sıfatına sahiptir.
            </p>
            <p>
                Kanun uyarınca şirket tarafından (“Veri Sorumlusu”)
                gerçekleştirilen kişisel veri işlemesine konu faaliyetler
                hakkında koşul ve şartlara yer verilmiş olup veri sahiplerinin
                bilgilendirilerek şeffaflığın sağlanması ve aşağıda belirtilen
                durumlar kapsamında açık rızalarının temini hedeflenmektedir.
                Gizlilik Politikası, şirketimizin internet sitesinde
                ({config?.Domain}) yayımlanır ve kişisel veri
                sahiplerinin talebi üzerine ilgili kişilerin erişimine sunulur.
            </p>
            <p>
                Bu doğrultuda, işbu Gizlilik Politikası (“Politika”), kişisel
                verilerin 6698 sayılı Kişisel Verilerin Korunması Kanunu
                (“Kanun”) ile tamamen uyumlu bir şekilde işlenmesi ve veri
                sahiplerini bu bağlamda bilgilendirmek amacıyla hazırlanmıştır.
                İşbu Politikadan ayrı olarak şirket çalışanları için “ BATI
                KARADENİZ KALKINMA AJASI Çalışanlarının Kişisel Verilerinin
                İşlenmesine İlişkin Politika” düzenlenmiştir.
            </p>
            <p>
                2-Bu Politika; Çalışan Adayları, Müşteriler, Şirket
                Hissedarları, Şirket Yetkilileri, Ziyaretçiler, İşbirliği İçinde
                Olduğumuz Kurumların, Alt İşverenlerin ve Tedarikçilerin
                Çalışanları, Hissedarları ve Yetkilileri ile Üçüncü Kişiler
                başta olmak üzere otomatik olan ya da herhangi bir veri kayıt
                sisteminin parçası olmak kaydıyla otomatik olmayan yollarla
                işlenen tüm kişisel verilerine ilişkindir.
            </p>
            <p>
                Yukarıda belirtilen kategorilerde kişisel veri sahipleri
                gruplarına ilişkin işbu Politika’nın uygulama kapsamı
                Politikanın tamamı olabileceği gibi yalnızca bir kısım hükümleri
                de olabilecektir.
            </p>
            <p>
                3-Kişisel verilerin işlenmesi ve korunması konusunda yürürlükte
                bulunan ilgili kanuni düzenlemeler öncelikle uygulama alanı
                bulacaktır. Yürürlükte bulunan mevzuat ve Politika arasında
                uyumsuzluk bulunması durumunda, Şirket yürürlükteki mevzuatın
                uygulama alanı bulacağını kabul etmektedir.
            </p>
            <p>
                4-Politika’nın kapsamı dahilinde kişisel verileri işlenen veri
                sahipleri aşağıdaki şekilde kategorize edilmiştir:
            </p>
            <table border={1} style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        <td>
                            <strong>Çalışan Adayları</strong>
                        </td>
                        <td>
                            Şirkete iş başvurusunda bulunarak veya herhangi bir
                            yolla özgeçmişini ve ilgili bilgilerini Şirkete
                            erişilebilir kılan gerçek kişiler.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <strong>İşbirliği içinde Olduğumuz</strong>
                            </div>
                            <div>
                                <strong>Kurumların, Alt İşverenlerin,</strong>
                            </div>
                            <div>
                                <strong>Tedarikçilerin</strong>
                            </div>
                            <div>
                                <strong>Çalışanları,</strong>
                            </div>
                            <div>
                                <strong>Hissedarları ve Yetkilileri</strong>
                            </div>
                        </td>
                        <td>
                            Şirket ile iş ilişkisi içerisinde bulunan
                            kurumların, alt işverenlerin, tedarikçilerin
                            çalışanları, hissedarları ve yetkilileri
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Müşteriler</strong>
                        </td>
                        <td>
                            Herhangi bir sözleşmesel ilişki olup olmadığına
                            bakılmaksızın Şirket tarafından yürütülen
                            faaliyetler kapsamında iş ilişkileri dolayısıyla
                            kişisel verileri elde edilen gerçek kişiler.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Ziyaretçiler</strong>
                        </td>
                        <td>
                            Şirketin fiziksel tesislerine çeşitli amaçlarla
                            girmiş olan, ziyaret eden gerçek kişiler.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Üçüncü Kişiler</strong>
                        </td>
                        <td>
                            Politikada tanımlanmamış olmasına rağmen işbu
                            Politika çerçevesinde kişisel verileri işlenen diğer
                            gerçek kişiler.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Şirket Hissedarı</strong>
                        </td>
                        <td>Şirketin hissedarı gerçek kişiler.</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Şirket Yetkilisi</strong>
                        </td>
                        <td>
                            Şirketin yönetim kurulu üyesi ve diğer yetkili
                            gerçek kişiler.
                        </td>
                    </tr>
                </tbody>
            </table>
            <p>5- Bu Politika’nın uygulanmasında;</p>
            <table border={1} style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        <td>
                            <strong>Açık Rıza</strong>
                        </td>
                        <td>
                            Belirli bir konuya ilişkin, bilgilendirilmeye
                            dayanan ve özgür iradeyle açıklanan rızadır
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Anonim Hale Getirme</strong>
                        </td>
                        <td>
                            Kişisel verilerin, başka verilerle eşleştirilerek
                            dahi hiçbir surette kimliği belirli veya
                            belirlenebilir bir gerçek kişiyle
                            ilişkilendirilemeyecek hale getirilmesidir.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Kişisel Veri</strong>
                        </td>
                        <td>
                            Kimliği belirli veya belirlenebilir gerçek kişiye
                            ait her türlü bilgidir.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Özel Nitelikte Kişisel Veri</strong>
                        </td>
                        <td>
                            Irk, etnik köken, siyasi düşünce, felsefi inanç,
                            din, mezhep veya diğer inançlar, kılık kıyafet,
                            dernek vakıf ya da sendika üyeliği, sağlık, ceza
                            mahkûmiyeti ve güvenlik tedbirleriyle ilgili veriler
                            ile biyometrik ve genetik veriler özel nitelikli
                            verilerdir.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Kişisel Verinin İşlenmesi</strong>
                        </td>
                        <td>
                            Kişisel verilerin tamamen veya kısmen otomatik olan
                            ya da herhangi bir veri kayıt sisteminin parçası
                            olmak kaydıyla otomatik olmayan yollarla elde
                            edilmesi, kaydedilmesi, depolanması, muhafaza
                            edilmesi, değiştirilmesi, yeniden düzenlenmesi,
                            açıklanması, aktarılması, devralınması, elde
                            edilebilir hâle getirilmesi, sınıflandırılması ya da
                            kullanılmasının engellenmesi gibi veriler üzerinde
                            gerçekleştirilen her türlü işlem.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Kurul</strong>
                        </td>
                        <td>Kişisel Verileri Koruma Kurulu</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Politika</strong>
                        </td>
                        <td>
                            Şirketin Kişisel Verilerin Korunması Ve İşlenmesi
                            Politikası’dır.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Veri İşleyen</strong>
                        </td>
                        <td>
                            Veri sorumlusunun verdiği yetkiye dayanarak onun
                            adına kişisel veri işleyen gerçek ve tüzel kişidir.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Veri Sorumlusu</strong>
                        </td>
                        <td>
                            Kişisel verilerin işlenme amaçlarını ve vasıtalarını
                            belirleyen, verilerin sistematik bir şekilde
                            tutulduğu yeri (veri kayıt sistemi) yöneten kişi
                            veri sorumlusudur.
                        </td>
                    </tr>
                </tbody>
            </table>
            <p>ifade eder.</p>
            <p>
                6-Şirketimiz’in Çalışan Adayları, Müşteriler, Şirket
                Hissedarları, Şirket Yetkilileri, Ziyaretçiler, İşbirliği İçinde
                Olduğumuz Kurumların, Alt İşverenlerin ve Tedarikçilerin
                Çalışanları, Hissedarları ve Yetkilileri ile Üçüncü Kişilere
                ilişkin kişisel verilerin işlenmesine ilişkin hususlar Kanun’a
                uygun şekilde işbu Politika metni kapsamında düzenlenmektedir.
            </p>
            <p>
                7- Veri sahibinin rızasıyla ya da Kanun’da sayılan diğer meşru
                nedenler gereği elde edilmiş kişisel veriler, veri ilgilisinin
                işbu politikada belirtilen ve aydınlatılmış rızasında belirtilen
                amaç veya kanuni dayanağın gerektirdiği ölçü ile sınırlı olarak
                işlenir. Kanuni dayanak ortadan kalktıktan sonra rızanın
                olmadığı veya geri alındığı durumlarda tüm kişisel verileriniz
                silinecek, yok edilecek veya anonimleştirilecektir.
            </p>
            <p>8-Gizlilik Politikası ile</p>
            <ul>
                <li>
                    Veri Sahibine ait olan hangi bilgilerin toplandığını ve söz
                    konusu veriler ile ne yapıldığını ne yapılmadığını ortaya
                    koymak,
                </li>
                <li>
                    Kanun kapsamında Veri Sahibinin, Veri Sorumlusunun ve üçüncü
                    kişilerin haklarını ve gizliliğini koruma konusunda
                    sorumluluklarını tespit etmek,
                </li>
                <li>
                    İşlevsel ve yararlı bir hizmet sunmak amacıyla paylaşılan
                    bilgilerin kullanım şeklini açıklamak, amaçlanmaktadır.
                </li>
            </ul>
            <p>
                9- İşbu metin ile veri sahipleri, kişisel verilerinin işlenmesi
                ve gizlilik politikası hakkında aydınlatılmış olduğunu ve
                kişisel verilerinin burada belirtilen şekilde kullanılmasına
                onay verdiğini kabul etmektedir.
            </p>
            <p>
                10-Veri Sorumlusunun işlediği kişisel veriler aşağıda belirtilen
                şekilde Kişisel Verilerin Korunması Kanunu’na (Kanun) uygun
                olarak kategorize edilmiştir. Aksi açıkça belirtilmedikçe, işbu
                Gizlilik Politikası kapsamında arz edilen hüküm ve koşullar
                kapsamında “Kişisel Veri” ifadesi aşağıda yer alan bilgileri
                kapsayacaktır.
            </p>
            <table border={1} style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        <td>
                            <strong>Kimlik Bilgisi</strong>
                        </td>
                        <td>
                            Ad-soyad, TC Kimlik numarası, uyruk bilgisi,
                            anne-baba adı, doğum yeri, doğum tarihi, cinsiyet ve
                            SGK numarası ile sınırlı olmamak kaydıyla; ehliyet,
                            nüfus cüzdanı, ikametgâh gibi dokümanlarda yer alan
                            tüm bilgiler
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>İletişim Bilgisi</strong>
                        </td>
                        <td>
                            Kimliği belirli veya belirlenebilir bir gerçek
                            kişiye ait olduğu açık olan;
                            <br />
                            kısmen veya tamamen otomatik şekilde veya veri kayıt
                            sisteminin bir parçası olarak otomatik olmayan
                            şekilde işlenen; telefon numarası, adres, e- mail
                            adresi, faks numarası, IP adresi gibi bilgiler.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Müşteri Bilgisi</strong>
                        </td>
                        <td>
                            Ticari faaliyetlerimiz ve bu çerçevede iş
                            birimlerimizin yürüttüğü operasyonlar neticesinde
                            ilgili kişi hakkında elde edilen ve üretilen
                            bilgiler.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Müşteri İşlem Bilgisi</strong>
                        </td>
                        <td>
                            Ürün ve hizmetlerimizin kullanımına yönelik kayıtlar
                            ile müşterinin ürün ve hizmetleri kullanımı için
                            gerekli olan talimatları ve talepleri gibi bilgiler.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>İşlem Güvenliği Bilgisi</strong>
                        </td>
                        <td>
                            Ticari faaliyetlerinin yürütülmesi sırasında teknik,
                            idari, hukuki ve ticari güvenliğin sağlanması için
                            işlenen kişisel veriler.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Risk Yönetimi Bilgisi</strong>
                        </td>
                        <td>
                            Ticari, teknik ve idari risklerimizi yönetebilmemiz
                            için bu alanlarda genel kabul görmüş hukuki, ticari
                            teamül ve dürüstlük kuralına uygun olarak kullanılan
                            yöntemler vasıtasıyla işlenen kişisel veriler
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Finansal Bilgi</strong>
                        </td>
                        <td>
                            Kişisel veri sahibi ile kurulan hukuki ilişkinin
                            tipine göre yaratılan her türlü finansal sonucu
                            gösteren bilgi, belge ve kayıtlara ilişkin işlenen
                            kişisel veriler
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Çalışan Aday Bilgisi</strong>
                        </td>
                        <td>
                            Şirket çalışanı olmak için başvuruda bulunmuş veya
                            ticari teamül ve dürüstlük kuralları gereği
                            şirketimizin insan kaynakları ihtiyaçları
                            doğrultusunda çalışan adayı olarak değerlendirilmiş
                            veya çalışma ilişkisi içerisinde olan bireylerle
                            ilgili işlenen kişisel veriler.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Hukuki İşlem Bilgisi</strong>
                        </td>
                        <td>
                            Hukuki alacak ve haklarımızın tespiti, takibi ve
                            borçlarımızın ifası kapsamında işlenen kişisel
                            veriler.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Teftiş Bilgisi</strong>
                        </td>
                        <td>
                            Şirketin kanuni yükümlülükleri ve şirket
                            politikalarına uyumu kapsamında işlenen kişisel
                            veriler
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Özel Nitelikli Kişisel Veri</strong>
                        </td>
                        <td>
                            KVK Kanunu’nun 6 maddesinde belirtildiği üzere;
                            kişilerin ırkı, etnik kökeni, siyasi düşüncesi,
                            felsefi inancı, dini, mezhebi veya diğer inançları,
                            kılık ve kıyafeti, dernek, vakıf ya da sendika
                            üyeliği, sağlığı, ceza mahkûmiyeti ve güvenlik
                            tedbirleriyle ilgili verileri ile biyometrik ve
                            genetik verileri,
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Pazarlama Bilgisi</strong>
                        </td>
                        <td>
                            Ürün ve hizmetlerimizin kişisel veri sahibinin
                            kullanım alışkanlıkları, beğenisi ve ihtiyaçları
                            doğrultusunda özelleştirilerek pazarlamasının
                            yapılmasına yönelik işlenen kişisel veriler ve bu
                            işleme sonuçları neticesinde yaratılan rapor ve
                            değerlendirmeler
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <strong>Fiziksel Mekan Güvenlik</strong>
                            </div>
                            <div>
                                <strong>Bilgisi</strong>
                            </div>
                        </td>
                        <td>
                            Kimliği belirli veya belirlenebilir bir gerçek
                            kişiye ait olduğu açık olan; kısmen veya tamamen
                            otomatik şekilde veya veri kayıt sisteminin bir
                            parçası olarak otomatik olmayan şekilde işlenen;
                            fiziksel mekana girişte, fiziksel mekanın içerisinde
                            kalış sırasında alınan kayıtlar ve belgelere ilişkin
                            kişisel veriler; kamera kayıtları ve güvenlik
                            noktasında alınan kayıtlar v.b.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Görsel/İşitsel Bilgi</strong>
                        </td>
                        <td>
                            Kimliği belirli veya belirlenebilir bir gerçek
                            kişiye ait olduğu açık olan; fotoğraf ve kamera
                            kayıtları (Fiziksel Mekan Güvenlik Bilgisi
                            kapsamında giren kayıtlar hariç), ses kayıtları ile
                            kişisel veri içeren belgelerin kopyası niteliğindeki
                            belgelerde yer alan veriler.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Talep / Şikayet Yönetim Bilgisi</strong>
                        </td>
                        <td>
                            Yöneltilmiş olan her türlü talep veya şikayetin
                            alınması ve değerlendirilmesine ilişkin kişisel
                            veriler.
                        </td>
                    </tr>
                </tbody>
            </table>
            <p>
                11-Kişisel Verilerin Korunması Kanunu 3 ve 7 maddeleri uyarınca
                anonim hale getirilen veriler, kişisel veri olarak kabul
                edilmeyecek ve bu verilere ilişkin işleme faaliyetleri işbu
                Gizlilik Politikası hükümleri ile bağlı olmaksızın
                gerçekleştirecektir.
            </p>
            <p>
                12- Şirketimiz tarafından Kişisel Verileri Koruma Kanunu Madde
                4’teki temel ilkelere ve bu Politika’da belirlenen esaslara
                uygun olarak kişisel verileri işlemektedir. Ayrıca Kanunun 5
                maddesinin 2 fıkrasında ve 6 maddenin 3 fıkrasında belirtilen
                kişisel veri işleme şartları içerisindeki amaçlarla ve
                koşullarla sınırlı olarak da kişisel veriler işlenmektedir. Bu
                amaçlar ve koşullar;
            </p>
            <ul>
                <li>Kanunlarda açıkça öngörülmesi,</li>
                <li>
                    Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda
                    bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin
                    kendisinin ya da bir başkasının hayatı veya beden
                    bütünlüğünün korunması için zorunlu olması,
                </li>
                <li>
                    Veri sahibi ile veri sorumlusu arasında herhangi bir
                    sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
                    olması kaydıyla, kişisel verilerin işlenmesinin gerekli
                    olması,
                </li>
                <li>Kanunlarda açıkça öngörülmesi,</li>
                <li>
                    Veri sorumlusunun hukuki yükümlülüklerini yerine
                    getirebilmesi için zorunlu olması,
                </li>
                <li>
                    Veri sahibinin kendisi tarafından alenileştirilmiş olması,
                </li>
                <li>
                    Bir hakkın tesisi, kullanılması veya korunması için veri
                    işlemenin zorunlu olması,
                </li>
                <li>
                    Veri sahibinin temel hak ve özgürlüklerine zarar vermemek
                    kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                    işlenmesinin zorunlu olması.
                </li>
            </ul>
            <p>
                Öte yandan, Kanun, kişilerin ırkı, etnik kökeni, siyasi
                düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları,
                kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı,
                ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile
                biyometrik ve genetik verileri “özel nitelikli” veya “hassas”
                kişisel veri olarak tanımlamış ve bunların işlenmesi için daha
                ağır şartlar öngörmüştür. Buna göre, özel nitelikli kişisel
                veriler, veri sahibinden açık rıza alınmış bulunan haller
                dışında ancak aşağıdaki koşullarda işlenebilecektir:
            </p>
            <ul>
                <li>
                    Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi
                    inancı, dini, mezhebi veya diğer inançları, kılık ve
                    kıyafeti, dernek, vakıf ya da sendika üyeliği, ceza
                    mahkûmiyeti ve güvenlik tedbirleriyle ilgili ver ileri ile
                    biyometrik ve genetik verileri, kanunlarda öngörülen
                    hâllerde işlenebilecektir.
                </li>
                <li>
                    Sağlıkla ilişkin kişisel veriler, ancak kamu sağlığının
                    korunması, koruyucu hekimlik, tıbbı teşhis, tedavi ve bakım
                    hizmetlerinin yürütülmesi, sağlık hizmetleri ile
                    finansmanının planlanması ve yönetimi amacıyla, sır saklama
                    yükümlülüğü altında bulunan kişiler veya yetkili kurum ve
                    kuruluşlar tarafından işlenebilecektir.
                </li>
            </ul>
            <p>
                13-Yukarıda belirtilen şartların bulunmaması halinde, Şirket,
                kişisel veri işleme faaliyetinde bulunmak için kişisel veri
                sahiplerinin açık rızalarına başvurmaktadır. Bu çerçevede
                kişisel veriler, bunlarla sınırlı olmamak üzere aşağıdaki
                amaçlarla işleyebilmektedir:
            </p>
            <ul>
                <li>
                    Şirket tarafından sunulan ürün ve hizmetlerden kişisel veri
                    sahiplerini faydalandırmak için gerekli çalışmaların yerine
                    getirilmesi amacı doğrultusunda; sözleşme süreçlerinin
                    takibi, müşteri ilişkileri, satış süreçlerinin yürütülmesi,
                    hukuki takiplerin yapılması, müşteri talep ve/veya
                    şikayetlerinin takibi,
                </li>
                <li>
                    Şirket tarafından yürütülen ticari faaliyetlerin
                    gerçekleştirilmesi için gerekli çalışmaların yapılması,
                    kurumsal iletişim faaliyetlerinin planlanması, iş
                    sürekliğinin sağlanması, bilgi teknolojileri alt yapısının
                    oluşturulması, finans işlerinin takibi, kurumsal yönetim
                    faaliyetlerin icrası, iş faaliyetlerine ilişkin
                    analizlerinin gerçekleştirilmesi, iş ortakları ve
                    tedarikçilerin bilgiye erişim yetkilerinin planlanması ve
                    icrası, iş faaliyetlerinin planlanması ve icrası, araştırma
                    ve geliştirme faaliyetlerinin planlanması ve icrası,
                </li>
                <li>
                    Şirketin insan kaynakları politikaları ve süreçlerinin
                    planlanması ve icra edilmesi amacı doğrultusunda;
                    çalışanların ve çalışan adaylarının iş akdi ve mevzuattan
                    kaynaklı yükümlülüklerin yerine getirilmesi, iş
                    faaliyetlerinin yürütebilmesi için ihtiyacı olan ürün ve
                    hizmetlerin temin edilmesi, iş faaliyetlerinin takibi ve
                    denetimi, yan haklar ve menfaatlerin planlanması ve icrası,
                    personel temin süreçlerinin yürütülmesi, performans
                    değerlendirme süreçlerinin planlanması, şirket içi eğitim
                    faaliyetlerinin planlanması ve icrası, insan kaynakları
                    süreçlerinin planlanması, üretim için gerekli olan insan
                    kaynakları ihtiyaçlarının planlanması ve icrası,
                </li>
                <li>
                    Şirket ile iş ilişkisi içerisinde olan kişilerin hukuki ve
                    ticari güvenliğinin temini amacı doğrultusunda; şirket
                    faaliyetlerinin şirket prosedürleri ve ilgili mevzuata uygun
                    olarak yürütülmesinin temini için gerekli operasyonel
                    faaliyetlerinin planlanması ve icrası, iş sağlığı ve
                    güvenliği süreçlerinin planlanması ve icrası, yetkili
                    kuruluşlara mevzuattan kaynaklı bilgi verilmesi, hukuk
                    işlerinin takibi, ziyaretçi kayıtlarının oluşturulması ve
                    takibi, şirket yerleşkeleri ve/veya tesislerinin
                    güvenliğinin temini, şirket operasyonlarının güvenliğinin
                    temini, şirket denetim faaliyetlerinin planlanması ve
                    icrası, verilerin doğru ve güncel olmasının sağlanması,
                    şirketin finansal risk süreçlerinin planlanması ve icrası,
                </li>
            </ul>
            <p>
                Şirket tarafından, izah edilen benzeri amaçları gerçekleştirmek
                için esas olarak kişilerin açık rızasının alınması
                hedeflenmektedir. Kanun’da sayılan istisnaların olduğu
                durumlarda bu amaçların gerçekleştirilmesi söz konusu istisnalar
                uyarınca sınırlı ve ölçülü kişisel veriler tutulmaktadır.
                Kişinin açık rızasının bulunmadığı durumlarda, Kanun’da
                belirtilen istisnalar çerçevesinde kişisel verilerin işlenmesi
                gerçekleştirilir. Kanun’daki istisnaların da kişisel veri
                işlenmesine izin vermemesi durumunda ve kişinin açık rızasının
                da bulunmaması halinde kişisel veriler işlenmez.
            </p>
            <p>
                14-Söz konusu kişisel bilgiler, Veri Sahibi ile iletişime geçmek
                veya Veri Sahibinin kimliği ifşa edilmeksizin çeşitli
                istatistiksel değerlendirmeler yapma, veri tabanı oluşturma ve
                pazar araştırmalarında bulunma amacıyla da kullanılabilecektir.
            </p>
            <p>
                15- Şirket, hizmet ilişkisi tesis ettiği çalışanlarının kişisel
                verilerini, kurulan hizmet sözleşmesinin ifası, karşılıklı
                edimlerin yerine getirilmesi ve başkaca Kanuni yükümlülüklerin
                ifası bakımından gerekli olduğu ölçüde rıza aranmaksızın
                işleyebilir. Şirket, çalışanlarına ait verilerin gizliliğini ve
                korunmasını temin eder. Bu kapsamda İşbu Politika’dan ayrı
                olarak şirket çalışanları için “{config?.Name}
                Çalışanlarının Kişisel Verilerinin İşlenmesine İlişkin Politika”
                düzenlenmiştir.
            </p>
            <p>
                Şirket, müstakbel çalışanları tarafından yapılan başvuru ve
                taleplerde ise başvuru ve talep sonuçlandırılana kadar başvuran
                tarafından sunulan özgeçmişler dahil tüm kişisel verileri, rıza
                aranmaksızın işler. Başvuru sürecinin olumsuz sonuçlanarak
                tamamlanmasından sonra işlenebilmesi ise ilgilinin rızasına
                bağlıdır. İlgilinin rıza göstermesi durumunda kişisel veriler,
                üçüncü kişilere aktarılabilir. Aksi durumlarda talep ve başvuru
                sürecinin kesin biçimde olumsuz olarak sonuçlandırılmasının
                ardından veri silinir, yok edilir veya anonimleştirilir. Talep
                veya başvurunun kısmen veya tamamen olumlu sonuçlandığı
                durumlarda, kişisel verilerin muhafaza ve işlenmesi, kurulan
                yeni hukuki ilişkinin koşullarına göre yapılır.
            </p>
            <p>
                16- Şirketimiz tarafından yürütülen kamera ile izleme faaliyeti,
                Özel Güvenlik Hizmetlerine Dair Kanun ve KVK Kanunu’nda sayılan
                kişisel veri işleme şartlarına uygun olarak yürütülmektedir.
            </p>
            <p>
                Şirket tarafından KVK Kanunu’nun 10 Maddesine uygun olarak,
                kişisel veri sahibi aydınlatılmaktadır. Şirket, kamera ile
                izleme faaliyetine ilişkin birden fazla yöntem ile bildirimde
                bulunmaktadır. Şirket tarafından kamera ile izleme faaliyetine
                yönelik olarak; alanların girişlerine izleme yapılacağına
                ilişkin bildirim yazısı asılmaktadır. Böylelikle, kişisel veri
                sahibinin temel hak ve özgürlüklerine zarar verilmesinin
                engellenmesi, şeffaflığın ve kişisel veri sahibinin
                aydınlatılmasının sağlanması amaçlanmaktadır.
            </p>
            <p>
                Şirket, KVK Kanunu’nun 4 maddesine uygun olarak, kişisel
                verileri işlendikleri amaçla bağlantılı, sınırlı ve ölçülü bir
                biçimde işlemektedir.
            </p>
            <p>
                Şirket tarafından video kamera ile izleme faaliyetinin
                sürdürülmesindeki amaç bu Politika’da sayılan amaçlarla
                sınırlıdır. Bu doğrultuda, güvenlik kameralarının izleme
                alanları, sayısı ve ne zaman izleme yapılacağı, güvenlik amacına
                ulaşmak için yeterli ve bu amaçla sınırlı olarak uygulamaya
                alınmaktadır. Kişinin mahremiyetini güvenlik amaçlarını aşan
                şekilde müdahale sonucu doğurabilecek alanlarda izlemeye tabi
                tutulmamaktadır.
            </p>
            <p>
                Şirket tarafından KVK Kanunu’nun 12 maddesine uygun olarak,
                kamera ile izleme faaliyeti sonucunda elde edilen kişisel
                verilerin güvenliğinin sağlanması için gerekli teknik ve idari
                tedbirler alınmaktadır.
            </p>
            <p>
                Canlı kamera görüntüleri ile dijital ortamda kaydedilen ve
                muhafaza edilen kayıtlara yalnızca sınırlı sayıda şirket
                çalışanının erişimi bulunmaktadır.
            </p>
            <p>
                Şirket tarafından; güvenliğin sağlanması ve bu Politika’da
                belirtilen amaçlarla, ziyaret eden misafirlerin giriş
                çıkışlarının takibine yönelik kişisel veri işleme faaliyeti
                gerçekleştirilmektedir. Şirketimize gelen kişilerin ad-soyad
                bilgileri yalnızca giriş çıkışlarının takibinin yapılması
                amacıyla işlenmekte ve ilgili kişisel veriler fiziki ve
                elektronik ortamda kayıt sistemine kaydedilmektedir.
            </p>
            <p>
                Şirket, ticari faaliyetini sürdürdüğü yerlerin güvenliğinin
                sağlanması amacıyla, merkez binalarında ve tesislerinde,
                güvenlik kamerasıyla izleme, kayıt, giriş kartı okutma ve kimlik
                kaydı faaliyeti ile misafir giriş çıkışlarının takibine yönelik
                kişisel veri işleme faaliyetinde bulunmaktadır. Güvenlik
                kamerası ile izleme faaliyeti ve girişlerde kimlik kontrolü,
                kart okutma ve bunların kaydı Şirketin ve diğer kişilerin
                güvenliğini sağlamaya ilişkin menfaatlerini korunması amacını
                taşımaktadır, Şirket tarafından, ilgili Kanun Madde 12’ye uygun
                olarak, kamera ile izleme faaliyeti, kimlik kaydı sonucunda elde
                edilen kişisel verilerin güvenliğinin sağlanması için gerekli
                teknik ve idari tedbirler alınmaktadır.
            </p>
            <p>
                17- Veri sorumlusu, kişisel verileri ve bu kişisel verilerin
                kullanılması ile elde edilen yeni verileri, Gizlilik Politikası
                ve Kişisel Verileri İşlenmesine İlişkin Aydınlatma ve Rıza Metni
                altında belirtilen amaçların gerçekleştirilebilmesi, sunulan
                hizmetlerden ilgili kişileri faydalandırmak için gerekli
                çalışmaların yapılması, ticari faaliyetlerin gerçekleştirilmesi
                ve buna bağlı iş süreçlerinin yürütülmesi, güvenliğin temin
                edilmesi, hileli ya da izinsiz kullanımların tespit edilmesi,
                operasyonel değerlendirme araştırılması ve işbu amaçlardan
                herhangi birisini gerçekleştirebilmek için e-mail ve SMS
                gönderimi yapanlar da dahil olmak üzere dış kaynak hizmet
                sağlayıcıları, (hosting servisleri), hukuk büroları, şirket
                yetkilileri, iş ortakları, kanunen yetkili kamu kurum ve
                kuruluşları, özel kurumlar gibi üçüncü kişiler ile
                paylaşabilecektir.
            </p>
            <p>
                18-Yukarıda belirtilen hukuki sebeplerle toplanan kişisel
                veriler yürürlükte bulunan mevzuat ve bu Gizlilik Politikası’nda
                belirtilen amaçlarla işlenebilmekte ve aktarılabilmektedir.
                Şirket meşru ve hukuka uygun kişisel veri işleme amaçları
                doğrultusunda aşağıda sayılan KVK Kanunu’nun 5 maddesinde
                belirtilen kişisel veri işleme şartlarından bir veya birkaçına
                dayalı ve sınırlı olarak kişisel verileri üçüncü kişilere
                aktarabilmektedir:
            </p>
            <ul>
                <li>Kişisel veri sahibinin açık rızası var ise,</li>
                <li>
                    Kanunlarda kişisel verinin aktarılacağına ilişkin açık bir
                    düzenleme var ise,
                </li>
                <li>
                    Kişisel veri sahibinin veya başkasının hayatı veya beden
                    bütünlüğünün korunması için zorunlu ise ve kişisel veri
                    sahibi fiili imkânsızlık nedeniyle rızasını açıklayamayacak
                    durumda ise veya rızasına hukuki geçerlilik tanınmıyorsa;
                </li>
                <li>
                    Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                    ilgili olmak kaydıyla sözleşmenin taraflarına ait kişisel
                    verinin aktarılması gerekli ise,
                </li>
                <li>
                    Şirket’in hukuki yükümlülüğünü yerine getirmesi için kişisel
                    veri aktarımı zorunlu ise,
                </li>
                <li>
                    Kişisel veriler, kişisel veri sahibi tarafından
                    alenileştirilmiş ise,
                </li>
                <li>
                    Kişisel veri aktarımı bir hakkın tesisi, kullanılması veya
                    korunmasıiçin zorunlu ise,
                </li>
                <li>
                    Kişisel veri sahibinin temel hak ve özgürlüklerine zarar
                    vermemek kaydıyla, Şirket’in meşru menfaatleri için kişisel
                    veri aktarımı zorunlu ise.
                </li>
            </ul>
            <p>
                Şirket gerekli özeni göstererek, gerekli güvenlik tedbirlerini
                alarak ve KVK Kurulu tarafından öngörülen yeterli önlemleri
                alarak; meşru ve hukuka uygun kişisel veri işleme amaçları
                doğrultusunda kişisel veri sahibinin özel nitelikli kişisel
                verilerini aşağıdaki durumlarda üçüncü kişilere
                aktarabilmektedir.
            </p>
            <ul>
                <li>Kişisel veri sahibinin açık rızası var ise veya</li>
                <li>
                    Kişisel veri sahibinin açık rızası yok ise; Kişisel veri
                    sahibinin sağlığı dışındaki özel nitelikli kişisel verileri
                    (ırk, etnik köken, siyasi düşünce, felsefi inanç, din,
                    mezhep veya diğer inançlar, kılık ve kıyafet, dernek, vakıf
                    ya da sendika üyeliği, ceza mahkûmiyeti ve güvenlik
                    tedbirleriyle ilgili veriler ile biyometrik ve genetik
                    verilerdir), kanunlarda öngörülen hallerde, Kişisel veri
                    sahibinin sağlığına ilişkin özel nitelikli kişisel verileri
                    ise ancak kamu sağlığının korunması, koruyucu hekimlik,
                    tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi,
                    sağlık hizmetleri ile finansmanının planlanması ve yönetimi
                    amacıyla, sır saklama yükümlülüğü altında bulunan kişiler
                    veya yetkili kurum ve kuruluşlar tarafından
                    aktarılabilmektedir.
                </li>
            </ul>
            <p>
                19- Şirket meşru ve hukuka uygun kişisel veri işleme amaçları
                doğrultusunda kişisel veri sahibinin açık rızası var ise veya
                kişisel veri sahibinin açık rızası yok ise aşağıdaki hallerden
                birinin varlığı durumunda kişisel verileri Yeterli Korumaya
                Sahip veya Yeterli Korumayı Taahhüt Eden Veri Sorumlusunun
                Bulunduğu Yabancı Ülkelere aktarabilmektedir:
            </p>
            <ul>
                <li>
                    Kanunlarda kişisel verinin aktarılacağına ilişkin açık bir
                    düzenleme var ise,
                </li>
                <li>
                    Kişisel veri sahibinin veya başkasının hayatı veya beden
                    bütünlüğünün korunması için zorunlu ise ve kişisel veri
                    sahibi fiili imkânsızlık nedeniyle rızasını açıklayamayacak
                    durumda ise veya rızasına hukuki geçerlilik tanınmıyorsa;
                </li>
                <li>
                    Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                    ilgili olmak kaydıyla sözleşmenin taraflarına ait kişisel
                    verinin aktarılması gerekli ise,
                </li>
                <li>
                    Şirket’in hukuki yükümlülüğünü yerine getirmesi için kişisel
                    veri aktarımı zorunlu ise,
                </li>
                <li>
                    Kişisel veriler, kişisel veri sahibi tarafından
                    alenileştirilmiş ise,
                </li>
                <li>
                    Kişisel veri aktarımı bir hakkın tesisi, kullanılması veya
                    korunması için zorunlu ise,
                </li>
                <li>
                    Kişisel veri sahibinin temel hak ve özgürlüklerine zarar
                    vermemek kaydıyla, Şirket ‘in meşru menfaatleri için kişisel
                    veri aktarımı zorunlu ise.
                </li>
            </ul>
            <p>
                20- Şirket gerekli özeni göstererek, gerekli güvenlik
                tedbirlerini alarak ve KVK Kurulu tarafından öngörülen yeterli
                önlemleri alarak; meşru ve hukuka uygun kişisel veri işleme
                amaçları doğrultusunda kişisel veri sahibinin özel nitelikli
                verilerini aşağıdaki durumlarda Yeterli Korumaya Sahip veya
                Yeterli Korumayı Taahhüt Eden Veri Sorumlusunun Bulunduğu
                Yabancı Ülkelere aktarabilmektedir.
            </p>
            <ul>
                <li>Kişisel veri sahibinin açık rızası var ise veya</li>
                <li>
                    Kişisel veri sahibinin açık rızası yok ise; Kişisel veri
                    sahibinin sağlığı dışındaki özel nitelikli kişisel verileri
                    (ırk, etnik köken, siyasi düşünce, felsefi inanç, din,
                    mezhep veya diğer inançlar, kılık ve kıyafet, dernek, vakıf
                    ya da sendika üyeliği, ceza mahkûmiyeti ve güvenlik
                    tedbirleriyle ilgili veriler ile biyometrik ve genetik
                    verilerdir), kanunlarda öngörülen hallerde, Kişisel veri
                    sahibinin sağlığına ilişkin özel nitelikli kişisel verileri
                    ise ancak kamu sağlığının korunması, koruyucu hekimlik,
                    tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi,
                    sağlık hizmetleri ile finansmanının planlanması ve yönetimi
                    amacıyla, sır saklama yükümlülüğü altında bulunan kişiler
                    veya yetkili kurum ve kuruluşlar tarafından işlenmesi
                    kapsamında.
                </li>
            </ul>
            <p>
                21-Yukarıda belirtilen hukuki sebeplerle toplanan kişisel
                veriler 6698 sayılı Kanun’un 5 ve 6 maddelerinde ve bu Gizlilik
                Politikası’nda belirtilen amaçlarla işlenebilmekte ve
                aktarılabilmektedir.
            </p>
            <p>22-Kanunun 11 maddesine göre kişisel veri sahipleri</p>
            <ul>
                <li>
                    Kendisi ile ilgili kişisel veri işlenip işlenmediğini
                    öğrenme,
                </li>
                <li>
                    Kendisi ile ilgili kişisel veri işlenmişse buna ilişkin
                    bilgi talep etme,
                </li>
                <li>
                    Kişisel verilerin işlenme amacını ve bunların amacına uygun
                    kullanılıp kullanılmadığını öğrenme,
                </li>
                <li>
                    Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                    üçüncü kişileri bilme,
                </li>
                <li>
                    Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
                    bunların düzeltilmesini isteme,
                </li>
                <li>
                    Kanun ve ilgili diğer kanun hükümlerine uygun olarak
                    işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
                    ortadan kalkması hâlinde kişisel verilerin silinmesini veya
                    yok edilmesini isteme,
                </li>
                <li>
                    Düzeltme, silme ve yok etme talepleri neticesinde yapılan
                    işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere
                    bildirilmesini isteme,
                </li>
                <li>
                    İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                    analiz edilmesi suretiyle kişinin kendisi aleyhine bir
                    sonucun ortaya çıkmasına itiraz etme,
                </li>
                <li>
                    Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                    zarara uğraması hâlinde zararın giderilmesini talep etme,
                    haklarına sahiptir.
                </li>
            </ul>
            <p>
                23- KVK Kanunu’nun 13üncü maddesinin 1inci fıkrası gereğince
                yukarıda belirtilen haklarınızı kullanmakla ilgili talebinizi,
                “yazılı” veya Kişisel Verilerin Korunması Kurulunun belirlediği
                diğer yöntemlerle Şirketimize iletmeniz gerekmektedir.
            </p>
            <p>
                Bu çerçevede Şirketimize KVK Kanunu’nun 11’inci maddesi
                kapsamında yapacağınız başvurularda, yukarıda belirtilen
                haklarınızı kullanmak için kimliğinizi tespit edici gerekli
                bilgiler ve kullanmak istediğiniz hakkınıza yönelik
                açıklamalarınızla birlikte talebinizi, KVK Kanunu’nun 11’inci
                maddesinde belirtilen hangi hakkınızın kullanımına ilişkin
                olduğunu da belirterek aşağıdaki adrese iadeli taahhütlü mektup
                yoluyla iletebilirsiniz:
            </p>
            <p style={{ textTransform: "uppercase" }}>
                {config?.Name}
                <br />
                ADRES: {config?.Name}
            </p>
            <p>
                Kişisel veri sahipleri adına üçüncü kişiler tarafından talepte
                bulunulması mümkün değildir. Kişisel veri sahibinin kendisi
                dışında bir kişinin talepte bulunması için konuya ilişkin olarak
                kişisel veri sahibi tarafından başvuruda bulunacak kişi adına
                düzenlenmiş özel vekâletname bulunmalıdır.
            </p>
            <p>
                24-KVK Kanunu’nun 13 maddesine uygun olarak, Şirketimiz, kişisel
                veri sahibinin yapmış olduğu başvuru taleplerini, talebin
                niteliğine göre en kısa sürede ve en geç 30 (otuz) gün içinde
                ücretsiz olarak sonuçlandırmaktadır. Ancak, işlemin ayrıca bir
                maliyet gerektirmesi hâlinde, KVK Kurulunca belirlenen
                tarifedeki ücretin alınması mümkündür.
            </p>
            <p>
                Şirketimiz, kişisel veri sahibinin başvuru talebini kabul
                edebileceği gibi, gerekçesini açıklayarak aşağıda sayılı
                nedenlerle reddedebilir ve cevabını ilgili kişiye yazılı olarak
                veya elektronik ortamda bildirebilir.
            </p>
            <ul>
                <li>Diğer kişilerin hak ve özgürlüklerini engellemesi,</li>
                <li>Orantısız çaba gerektirmesi,</li>
                <li>Bilginin kamuya açık bir bilgi olması,</li>
                <li>Başkalarının gizliliğini tehlikeye atması,</li>
            </ul>
            <p>
                KVK Kanunu uyarınca kapsam dışında kalan hallerden birinin
                mevcut olması, Kişisel veri sahibi başvurusunun reddedilmesi,
                verilen cevabı yetersiz bulması veya süresinde başvurusuna cevap
                verilmemesi hallerinde, veri sorumlusunun cevabını öğrendiği
                tarihten itibaren otuz ve her halde başvuru tarihinden itibaren
                altmış gün içinde KVK Kurulu’na şikayette bulunma hakkına
                sahiptir.
            </p>
            <p>
                Şirket, ilgili mevzuatta belirlenen veya işbu Gizlilik
                Politikası’nda ifade edilen şartlarda, kişisel verilerin hukuka
                aykırı olarak işlenmemesini, kişisel verilere hukuka aykırı
                olarak erişilmemesini ve kişisel verilerin muhafazasını sağlamak
                amacıyla gerekli teknik ve idari tedbirleri almaktadır. Veri
                sorumlusu ayrıca, veri sahibinden elde ettiği kişisel verileri
                işbu Gizlilik Politikası ve Kişisel Verilerin Korunması Kanunu
                hükümlerine aykırı olarak başkasına açıklamamakta ve işleme
                amacı dışında kullanmamaktadır.
            </p>
            <p>
                25-İşbu Gizlilik Politikası değişen şartlara ve mevzuata uyum
                sağlamak amacıyla zaman zaman güncellenebilecektir.
            </p>
            <p>
                26-Kanun kapsamında kişisel verilerin saklanması için herhangi
                bir süre belirlenmemiş olmakla birlikte, genel ilkeler uyarınca
                kişisel verilerin ilgili mevzuatta öngörülen veya işlendikleri
                amaç için gerekli olan süre kadar muhafaza edilmesi esastır.
                Veri Sorumlusu Şirket, söz konusu ilkeye uygun bir şekilde
                saklama süreleri tespit etmek adına, her bir veri işleme süreci
                ile ilgili olarak yürürlükte bulunan mevzuatı ve sürecin amacını
                esas alarak bir değerlendirme yapmaktadır. Bu doğrultuda asgari
                olarak yasal yükümlülüklerinin gerektirdiği süre ile ve ilgili
                Kanuna konu zamanaşımı süreleri dolana kadar kişisel verileri
                saklanmaktadır.
            </p>
            <p>
                Kişisel veriler, Veri Sorumlusu ile aranızda doğabilecek
                herhangi bir uyuşmazlık durumunda, uyuşmazlık kapsamında gerekli
                savunmaların gerçekleştirilebilmesi amacıyla olmak üzere
                saklanabilecektir. Bahsi geçen sürelerin sona ermesi durumu da
                dahil olmak üzere herhangi bir süreç kapsamında ilgili kişisel
                verinin işleme amacının ortadan kalkması ile birlikte kişisel
                veriler Kanuna uygun bir şekilde anonimleştirilmekte, silinmekte
                veya yok edilmektedir.
            </p>
            <p>
                27- Topladığımız kişisel verilerinizin doğru ve gerektiğinde
                güncel olması gerekmektedir. Bu nedenle, kişisel verilerinizde
                herhangi bir değişiklik meydana gelmesi halinde, bu hususu
                Şirketimizin ilgili birimine bildirebilirsiniz.
            </p>
            <p>
                28-Şirketimiz, KVK Kanunu’ndaki yükümlülükleri yerine getirmek
                ve işbu Politikada belirtilen hususların uygulanmasına yönelik
                olarak Şirket içerisinde gerekli görevlendirmeleri yapmakta ve
                buna uygun olarak prosedürleri oluşturmaktadır.
            </p>
            <p>
                Yukarıda sıralanan maddeleri ihtiva eden politika, “BATI
                KARADENİZ KALKINMA AJASI Kişisel Verilerinin Korunması,
                İşlenmesi ve Gizlilik Politikasına İlişkin Aydınlatma ve Rıza
                Metni” başta olmak üzere diğer ilgili aydınlatma ve rıza
                metinleri ile birlikte kişisel veri sahibine sunulmaktadır.
                Ayrıca kişisel veri sahiplerinin talebi halinde de ilgili
                kişilere söz konusu politika sunulmakta ve erişimine imkan
                sağlanmaktadır.
            </p>
            <p style={{ textAlign: "center" }}>
                <strong style={{ textTransform: "uppercase" }}>
                    {config?.Name}
                </strong>
                <br />
                <strong>KİŞİSEL VERİ SAKLAMA VE İMHA POLİTİKASI</strong>
            </p>
            <p>
                1- Kişisel Veri Saklama ve İmha Politikası (“Politika”), 6698
                Sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) ve Kişisel
                Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi
                Hakkında Yönetmelik (“Yönetmelik”) uyarınca yükümlülüklerimizi
                konu usul ve esasları belirleyerek ve veri sahiplerini, kişisel
                verilerin işlendikleri amaç için gerekli olan azami saklama
                süresinin belirlenmesi esasları ile silme, yok etme ve anonim
                hale getirme süreçleri hakkında bilgilendirmek amacıyla veri
                sorumlusu sıfatıyla {config?.Name} (“Şirket”)
                tarafından hazırlanmıştır.
            </p>
            <p>
                2-Bu Politika kapsamında, herhangi bir veri kayıt sisteminin
                parçası olmak kaydıyla otomatik veya otomatik olmayan yollarla
                işlenen gerçek kişiler olarak müşteriler, müşteri adayları,
                çalışan adayları, çalışanlar, şirket hissedarları, şirket
                yetkilileri, ziyaretçiler, iş ortakları, işbirliği içinde
                olduğumuz kurumların, alt işverenlerin ve tedarikçilerin
                çalışanları, hissedarları ve yetkilileri ile üçüncü kişiler
                bulunmaktadır.
            </p>
            <p>
                Politika, Şirketimizce yönetilen, tüm kişisel verilerin
                işlenmesi ve korunmasına yönelik yürütülen faaliyetlerde
                uygulanmaktadır.
            </p>
            <p>
                3-İşbu politika şirketimizin internet sitesinde
                ({config?.Domain}) yayımlanır ve kişisel veri
                sahiplerinin talebi üzerine ilgili kişilerin erişimine sunulur.
            </p>
            <p>4-Bu Politika’nın uygulanmasında kategorisini,</p>
            <ul>
                <li>
                    <strong>İlgili Kişi:</strong>&nbsp;Verilerin teknik olarak
                    depolanması, korunması ve yedeklenmesinden sorumlu olan kişi
                    ya da birim hariç olmak üzere veri sorumlusu organizasyonu
                    içerisinde veya veri sorumlusundan aldığı yetki ve talimat
                    doğrultusunda kişisel verileri işleyen kişileri,
                </li>
                <li>
                    <strong>İmha:</strong>&nbsp;Kişisel verilerin silinmesi, yok
                    edilmesi veya anonim hale getirilmesini,
                </li>
                <li>
                    <strong>Kanun:</strong>&nbsp;6698 sayılı Kişisel Verilerin
                    Korunması Kanunu’nu,
                </li>
                <li>
                    <strong>Kayıt ortamı:</strong>&nbsp;Tamamen veya kısmen
                    otomatik olan ya da herhangi bir veri kayıt sisteminin
                    parçası olmak kaydıyla otomatik olmayan yollarla işlenen
                    kişisel verilerin bulunduğu her türlü ortamı,
                </li>
                <li>
                    <strong>Kişisel veri:</strong>&nbsp;Kimliği belirli veya
                    belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi,
                </li>
                <li>
                    <strong>Kişisel veri sahibi:</strong>&nbsp;Kişisel verisi
                    işlenen gerçek kişiyi,
                </li>
                <li>
                    <strong>Kişisel verinin işlenmesi:</strong>&nbsp;Kişisel
                    verilerin tamamen veya kısmen otomatik olan ya da herhangi
                    bir veri kayıt sisteminin parçası olmak kaydıyla otomatik
                    olmayan yollarla elde edilmesi, kaydedilmesi, depolanması,
                    muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi,
                    açıklanması, aktarılması, devralınması, elde edilebilir hale
                    getirilmesi, sınıflandırılması ya da kullanılmasının
                    engellenmesi gibi veriler üzerinde gerçekleştirilen her
                    türlü işlemi,
                </li>
                <li>
                    <strong>Kişisel veri işleme envanteri:</strong>&nbsp;Veri
                    sorumlularının iş süreçlerine bağlı olarak gerçekleştirmekte
                    oldukları kişisel verileri işleme faaliyetlerini, kişisel
                    verileri işleme amaçları, veri kategorisi, aktarılan alıcı
                    grubu ve veri konusu kişi grubuyla ilişkilendirerek
                    oluşturdukları ve kişisel verilerin işlendikleri amaçlar
                    için gerekli olan azami süreyi, yabancı ülkelere aktarımı
                    öngörülen kişisel verileri ve veri güvenliğine ilişkin
                    alınan tedbirleri açıklayarak detaylandırdıkları envanteri,
                </li>
                <li>
                    <strong>Kurul:</strong>&nbsp;Kişisel Verileri Koruma
                    Kurulu’nu,
                </li>
                <li>
                    <strong>Kurum:</strong>&nbsp;Kişisel Verileri Koruma
                    Kurumu’nu,
                </li>
                <li>
                    <strong>Özel nitelikli kişisel veri:</strong>&nbsp;Kişilerin
                    ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini,
                    mezhebi veya diğer inançları, kılık ve kıyafeti, dernek,
                    vakıf ya da sendika üyeliği, sağlığı, ceza mahkumiyeti ve
                    güvenlik tedbirleriyle ilgili verileri ile biyometrik ve
                    genetik verileri,
                </li>
                <li>
                    <strong>Periyodik imha:</strong>&nbsp;Kanunda yer alan
                    kişisel verilerin işlenme şartlarının tamamının ortadan
                    kalması durumunda kişisel verileri saklama ve imha
                    politikasında belirtilen ve tekrar eden aralıklarla re’sen
                    gerçekleştirilecek silme, yok etme veya anonim hale getirme
                    işlemini,
                </li>
                <li>
                    <strong>Veri Saklama ve İmha Politikası:</strong>&nbsp;Veri
                    sorumlularının, kişisel verilerin işlendikleri amaç için
                    gerekli olan azami süreyi belirleme işlemi ile silme, yok
                    etme ve anonim hale getirme işlemi için dayanak yaptıkları
                    işbu Politikayı,
                </li>
                <li>
                    <strong>
                        Kişisel Verilerin Korunması, İşlenmesi Ve Gizlilik
                        Politikası:
                    </strong>
                    &nbsp;şirketin internet adresinde yer alan, kişisel
                    verilerin yönetilmesine ilişkin usul ve esasları belirleyen
                    politikayı,
                </li>
                <li>
                    <strong>Sicil:</strong>&nbsp;Kişisel Verileri Koruma Kurumu
                    Başkanlığı tarafından tutulan veri sorumluları sicilini,
                </li>
                <li>
                    <strong>Veri işleyen:</strong>&nbsp;Veri sorumlusunun
                    verdiği yetkiye dayanarak onun adına kişisel veri işleyen
                    gerçek ve tüzel kişiyi,
                </li>
                <li>
                    <strong>Veri kayıt sistemi:</strong>&nbsp;Kişisel verilerin
                    belirli kriterlere göre yapılandırılarak işlendiği kayıt
                    sistemini,
                </li>
                <li>
                    <strong>Veri sorumlusu:</strong>&nbsp;Kişisel verilerin
                    işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt
                    sisteminin kurulmasından ve yönetilmesinden sorumlu olan
                    gerçek veya tüzel kişiyi ifade eder.
                </li>
            </ul>
            <p>
                Bu Politika’da yer almayan tanımlar için Kanun’daki tanımlar
                geçerlidir.
            </p>
            <p>
                5-Şirketin bütün birim yöneticileri, birimlerinde kişisel
                verilerin işlenmesi, saklanması ve imhası ile ilgili teknik ve
                idari önlemlerin usulüne uygun uygulanmasına etkin destek verir.
                Birim yöneticileri bu amaçla; birim çalışanlarının eğitimi ve
                farkındalıklarının artırılmasını sağlar, işlemleri izleyip
                denetler, kişisel verilerin hukuka aykırı olarak işlenmesinin ve
                işlenen verilere hukuka aykırı olarak erişilmesinin önlenmesine,
                veri güvenliğine yönelik teknik ve idari önlemlerin alınması ve
                uygulanmasına yardımcı olur.
            </p>
            <p>
                İlgili kullanıcıların kişisel verilerin korunması hususunda
                bilgi ve farkındalıkları artırılarak, kişisel verilerle ilgili
                işleme, saklama ve imha işlemlerinin mevzuata uygun olarak
                yerine getirilmesine aktif destek verir.
            </p>
            <p>
                Kişisel verilerin saklama ve imha süreçlerinde görev alanların
                unvanları, birimleri ve görev tanımları şöyledir:
            </p>
            <ul>
                <li>
                    <strong>Genel Müdür :</strong>&nbsp;Veri sorumlusu
                    temsilcisi sıfatıyla, kişisel verilerin korunması ve imhası
                    ile ilgili tüm işlemlerin yapılması ve politikanın
                    uygulanmasından sorumludur.
                </li>
                <li>
                    <strong>İnsan Kaynakları Yöneticisi :</strong>
                    &nbsp;Politikanın hazırlanması, geliştirilmesi, yürütülmesi,
                    ilgili ortamlarda
                    <div>
                        yayınlanması ve güncellenmesi, görevi dahilinde olan
                        süreçlerin saklama süresine uygunluğunun sağlanması ile
                        periyodik imha süresi uyarınca kişisel veri imha
                        sürecinin yönetimi, eğitim ve bilgilendirmeden
                        sorumludur.
                    </div>
                </li>
                <li>
                    <strong>Muhasebe Yöneticisi :</strong>&nbsp;Politikanın
                    hazırlanması, geliştirilmesi, yürütülmesi, ilgili ortamlarda
                    yayınlanması ve güncellenmesi, görevi dahilinde olan
                    süreçlerin saklama süresine uygunluğunun sağlanması ile
                    periyodik imha süresi uyarınca kişisel veri imha sürecinin
                    yönetiminden sorumludur.
                </li>
                <li>
                    <strong>Bilgi Sistemleri Yöneticisi :</strong>
                    &nbsp;Verilerin teknik olarak depolanması, korunması ve
                    yedeklenmesinden, politikanın uygulanmasında gereksinim
                    duyulan teknik çözümlerin belirlenmesi ve uygulanmasından
                    sorumludur.
                </li>
                <li>
                    <strong>Diğer Birim Yöneticileri :</strong>&nbsp;Kendi
                    birimlerinde politikanın uygulanması ve uygulamanın
                    izlenmesi ve denetlenmesi, görevi dahilinde olan süreçlerin
                    saklama süresine uygunluğunun sağlanması ile periyodik imha
                    süresi uyarınca kişisel veri imha sürecinin yönetiminden
                    sorumludur.
                </li>
                <li>
                    <strong>İlgili Kullanıcı ve Veri İşleyenler :</strong>
                    &nbsp;Veri işleme ve saklanması ile ilgili işlemlerin usul
                    ve yasaya uygun olmasından sorumludur.
                </li>
                <li>
                    <strong>Özel Yetkili İlgili Kullanıcı :</strong>
                    &nbsp;Prosedür veya ilgili kişinin isteği üzerine silinen
                    kişisel verilerin yok edilinceye kadar korunması,
                    saklanması, ilgili kullanıcılar tarafından erişilmemesinden
                    sorumludur.
                </li>
            </ul>
            <p>
                6-Şirket nezdinde saklanan kişisel veriler, ilgili verinin
                niteliğine uygun bir kayıt ortamında tutulmaktadır. Kişisel
                verilerin saklanması için kullanılan kayıt ortamları aşağıda
                belirtilmektedir. Öte yandan kişisel verilere nitelikleri gereği
                burada belirtilen ortamlardan farklı bir ortamda yer
                verilebilir. Her halde veri sorumlusu şirket, kişisel verileri
                Kanun’a, Kişisel Verilerin Korunması, İşlenmesi ve Gizlilik
                Politikası’na ve işbu Kişisel Veri Saklama ve İmha Politikası’na
                uygun olarak uluslararası veri güvenliği prensipleri
                çerçevesinde işlemekte ve korumaktadır.
            </p>
            <p>
                <strong>Elektronik Ortamlar;</strong>&nbsp;Sunucular,
                taşınabilir diskler, yazılımlar, bilgi güvenliği cihazları,
                çalışan bilgisayarları, optik diskler, çıkartılabilir bellekler,
                yazıcı, tarayıcı ve fotokopi makinesi gibi sair dijital
                ortamlardır.
            </p>
            <p>
                <strong>Fiziki Ortamlar;</strong>&nbsp;Kağıt, manuel veri kayıt
                sistemleri, yazılı, basılı, görsel ortamlar gibi verilerin kağıt
                ya da mikrofilmler üzerine basılarak tutulduğu sair ortamlardır.
            </p>
            <p>
                <strong>Bulut Ortamlar;</strong>&nbsp;Şirket nezdinde yer
                almamakla birlikte, şirketin kullanımında olan ve şifrelenmiş
                internet tabanlı sistemlerin kullanıldığı ortamlardır.
            </p>
            <p>
                7- Kişisel verilerinizin güvenli bir şekilde saklanması, hukuka
                aykırı olarak işlenmesi, erişilmesinin önlenmesi ve verilerin
                hukuka uygun olarak imha edilmesi amacıyla KVKK’nın 12
                Maddesindeki ilkeler çerçevesinde, alınmış olan tüm idari ve
                teknik tedbirler aşağıda belirtilmiştir.
            </p>
            <p>
                <strong>Teknik Tedbirler</strong>
            </p>
            <p>
                Kişisel verilerin saklandığı tüm ortamların ilgili verinin ve
                verinin tutulduğu ortamın niteliklerine uygun olarak aşağıdaki
                teknik tedbirleri almaktadır:
            </p>
            <ul>
                <li>
                    Kişisel verilerin tutulduğu ortamlarda yalnızca teknolojik
                    gelişmelere uygun güncel ve güvenli sistemler
                    kullanılmaktadır.
                </li>
                <li>
                    Kişisel verilerin tutulduğu ortamlara yönelik güvenlik
                    sistemleri kullanılmaktadır.
                </li>
                <li>
                    Bilişim sistemleri üzerindeki güvenlik zafiyetlerinin
                    tespitine yönelik güvenlik testleri ve araştırmaları
                    yapılmakta, yapılan test ve araştırmaların sonucunda tespit
                    edilen mevcut ya da muhtemel risk teşkil eden hususlar
                    giderilmektedir.
                </li>
                <li>
                    Kişisel verilerin tutulduğu ortamlara veriye erişim
                    kısıtlanarak yalnızca yetkili kişilerin, kişisel verinin
                    saklanma amacı ile sınırlı olarak bu verilere erişmesine
                    izin verilmekte ve tüm erişimler kayıt altına alınmaktadır.
                    Erişimin sınırlandırılmasında verinin özel nitelikli olup
                    olmadığı ve önem derecesi de dikkate alınır.
                </li>
                <li>
                    Şirket nezdinde kişisel verilerin tutulduğu ortamların
                    güvenliğini sağlamak üzere yeterli teknik personel
                    bulundurmaktadır. Bilgi teknolojileri birimlerinde
                    çalışanların kişisel verilere erişim yetkilerinin kontrol
                    altında tutulmasını sağlar
                </li>
                <li>
                    Kişiselverilerin yok edilmesi geri dönüştürülemeyecek ve
                    denetim izi bırakmayacak şekilde sağlanır.
                </li>
                <li>
                    Kanunun 12 maddesi uyarınca, kişisel verilerin saklandığı
                    her türlü dijital ortam, bilgi güvenliği gereksinimlerini
                    sağlayacak şekilde şifreli yöntemler ile korunur.
                </li>
            </ul>
            <p>
                <strong>İdari Tedbirler</strong>
            </p>
            <p>
                Kişisel verilerin saklandığı tüm ortamların ilgili verinin ve
                verinin tutulduğu ortamın niteliklerine uygun olarak aşağıdaki
                idari tedbirleri almaktadır:
            </p>
            <ul>
                <li>
                    Kişisel verilere erişimi olan tüm şirket çalışanlarının
                    bilgi güvenliği, kişisel veriler ve özel hayatın gizliliği
                    konularında farkındalıklarının artırılması ve
                    bilinçlendirilmesi için çalışmalar yapılmaktadır.
                </li>
                <li>
                    Bilgi güvenliği, özel hayatın gizliliği ve kişisel verilerin
                    korunması alanındaki gelişmeleri takip etmek ve gerekli
                    aksiyonları almak üzere hukuki ve teknik danışmanlık hizmeti
                    alınmaktadır.
                </li>
                <li>
                    Kişisel verilerin teknik ya da hukuki gereklilikler
                    nedeniyle üçüncü kişilere aktarılması halinde ilgili üçüncü
                    kişilerle kişisel verilerin korunması amacıyla protokoller
                    imzalanmakta, ilgili üçüncü kişilerin bu protokollerdeki
                    yükümlülüklerine uyması için gerekli tüm özen
                    gösterilmektedir.
                </li>
                <li>
                    İşlenen kişisel verilerin hukuka aykırı yollarla başkaları
                    tarafından elde edilmesi hâlinde, bu durumu en kısa sürede
                    ilgilisine ve Kurul’a bildirir.
                </li>
                <li>
                    Şirket nezdinde Kanun hükümlerinin uygulanmasını sağlamak
                    amacıyla gerekli denetimleri yapar ve yaptırır. Denetimler
                    sonucunda ortaya çıkan gizlilik ve güvenlik zafiyetlerini
                    giderir.
                </li>
            </ul>
            <p>
                8-Veri sahiplerine ait kişisel veriler, şirket tarafından
                özellikle ticari faaliyetlerin sürdürülebilmesi, hukuki
                yükümlülüklerin yerine getirilebilmesi, çalışan haklarının ve
                yan haklarının planlanması ve ifası, müşteri ilişkilerinin
                yönetilebilmesi ve Kişisel Verilerin Korunması,İşlenmesi Ve
                Gizlilik Politikasında yer alan diğer amaçlarla fiziki veya
                elektronik ortamlarda güvenli bir biçimde KVKK ve diğer ilgili
                mevzuatta belirtilen sınırlar çerçevesinde saklanmaktadır.
                Şirket nezdinde bulunan kişisel veriler ilgili kişinin talebi
                halinde ya da Kanun’un 5’nci ve 6’ncı maddelerinde sayılan
                nedenlerin ortadan kalkması halinde resen işbu imha politikası
                uyarınca silinir, yok edilir veya anonim hale getirilir.
                Kanun’un 5’nci ve 6’ncı maddelerinde sayılan nedenler
                aşağıdakilerden ibarettir:
            </p>
            <ul>
                <li>Kanunlarda açıkça öngörülmesi.</li>
                <li>
                    Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda
                    bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin
                    kendisinin ya da bir başkasının hayatı veya beden
                    bütünlüğünün korunması için zorunlu olması.
                </li>
                <li>
                    Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                    ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
                    verilerin işlenmesinin gerekli olması.
                </li>
                <li>
                    Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi
                    için zorunlu olması.
                </li>
                <li>
                    İlgili kişinin kendisi tarafından alenileştirilmiş olması.
                </li>
                <li>
                    Bir hakkın tesisi, kullanılması veya korunması için veri
                    işlemenin zorunlu olması.
                </li>
                <li>
                    İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                    kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                    işlenmesinin zorunlu olması.
                </li>
            </ul>
            <p>
                9-Şirket tarafından kişisel verilerin silinmesi ve yok edilmesi
                tekniklerine ilişkin usul ve esaslar aşağıda sayılmıştır.
            </p>
            <p>
                <strong>KİŞİSEL VERİLERİN SİLİNMESİ</strong>
            </p>
            <p>
                <strong>
                    Kağıt Ortamında Bulunan Kişisel Verilerin Karartılması:
                </strong>
                &nbsp;ilgili evrak üzerindeki kişisel verilerin fiziksel olarak
                kesilerek belgeden çıkartılması veya geri döndürülemeyecek ve
                teknolojik çözümlerle okunamayacak şekilde sabit mürekkep
                kullanılarak görünemeyecek hale getirilmesi yöntemidir.
            </p>
            <p>
                <strong>Yazılımdan Güvenli Olarak Silme:</strong>&nbsp;Bulut
                ortamda ya da yerel dijital ortamlarda tutulan kişisel verilerin
                silinerek bir daha ulaşılamayacak hale getirilmesi yöntemidir.
            </p>
            <p>
                <strong>KİŞİSEL VERİLERİN YOK EDİLMESİ</strong>
            </p>
            <p>
                <strong>Fiziksel Yok Etme:</strong>&nbsp;Kişisel verinin
                sonradan kullanılamayacak biçimde fiziksel olarak yok edilmesi
                sistemi uygulanmaktadır. Kağıt ortamında bulunan belgeler evrak
                imha makineleri ile tekrar bir araya getirilemeyecek şekilde yok
                edilir. Kişisel veri barındıran optik ve manyetik medya ise
                eritilme, yakılma veya toz haline getirilme gibi fiziksel olarak
                yok edilir.
            </p>
            <p>
                <strong>De-manyetize Etme:</strong>&nbsp;Manyetik medyanın
                yüksek manyetik alanlara maruz kalacağı özel cihazlardan
                geçirilerek üzerindeki verilerin okunamaz bir biçimde bozulması
                yöntemidir.
            </p>
            <p>
                <strong>Üzerine yazma:</strong>&nbsp;Özel yazılımlar aracılığı
                ile manyetik medya ve yeniden yazılabilir optik medya üzerinden
                en az yedi kez 0 ve 1’lerden oluşan rastgele veriler yazılarak
                eski verinin okunabilmesi ve kurtarılabilmesini ortadan kaldıran
                yok etme yöntemidir.
            </p>
            <p>
                <strong>KİŞİSEL VERİLERİN ANONİMLEŞTİRİLMESİ</strong>
            </p>
            <p>
                <strong>Değişkenleri çıkarma:</strong>&nbsp;İlgili kişiye ait
                toplanılan verilerin bir araya getirilmesinden sonra oluşturulan
                veri setindeki değişkenlerden yüksek dereceli betimleyici
                olanların çıkartılarak anonim hale getirilmesi yöntemidir.
            </p>
            <p>
                <strong>Bölgesel gizleme:</strong>&nbsp;Tek bir verinin çok az
                görülebilir bir kombinasyon yaratması sebebi ile belirleyici
                niteliği mevcut ise ilgili verinin gizlenmesi anonimleştirmeyi
                sağlamaktadır. İstisna durumda olan veriye ilişkin ayırt edici
                nitelikte olabilecek bilgilerin silinmesi işlemidir.
            </p>
            <p>
                <strong>Genelleştirme:</strong>&nbsp;Birçok kişiye ait kişisel
                verinin bir araya getirilip, ayırt edici bilgileri kaldırılarak
                istatistiki veri haline getirilmesi işlemidir.
            </p>
            <p>
                <strong>Alt ve Üst Sınır Kodlama:</strong>&nbsp;Önceden
                tanımlanmış kategorilerin yer aldığı bir veri grubundaki
                değerlerin belirli bir ölçüt belirlenerek birleştirilmesiyle
                anonim hale getirilmesi yöntemidir.
            </p>
            <p>
                <strong>Mikro birleştirme:</strong>&nbsp;Tüm veriler ilk olarak
                anlamlı bir sıraya dizilerekgruplara ayrılıp, grupların
                ortalaması alınarak elde edilen değer mevcut gruptaki ilgili
                verilerin yerine yazılarak anonimleştirme sağlanır.
            </p>
            <p>
                <strong>Veri karma ve bozma:</strong>&nbsp;Kişisel veri
                içerisindeki doğrudan ya da dolaylı tanımlayıcılar başka
                değerlerle karıştırılarak ya da bozularak ilgili kişi ile
                ilişkisi koparılır ve tanımlayıcı niteliklerini kaybetmeleri
                sağlanır.
            </p>
            <p>
                <strong>10-Saklama ve İmha Süreleri</strong>
            </p>
            <table border={1} style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        <td>
                            <strong>SÜREÇ</strong>
                        </td>
                        <td>
                            <strong>SAKLAMA SÜRESİ</strong>
                        </td>
                        <td>
                            <strong>İMHA SÜRESİ</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            İşe alım evrakları ile Sosyal Güvenlik Kurumuna
                            gerçekleştirilen; hizmet süresine ve ücrete dair
                            bildirimlere esas özlük verileri
                        </td>
                        <td>
                            Hizmet akdinin devamında ve hitamını takip eden
                            takvim yılı yılbaşından itibaren 10 yıl müddetle
                            muhafaza edilir.
                        </td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>
                            İşe alım evrakları ile Sosyal Güvenlik Kurumuna
                            gerçekleştirilen; hizmet süresine ve ücrete dair
                            bildirimlere esas özlük verileri dışında kalan özlük
                            verileri
                        </td>
                        <td>
                            Hizmet akdinin devamında ve hitamını takip eden
                            takvim yılı yılbaşından itibaren 10 yıl müddetle
                            saklanır
                        </td>
                        <td>Saklama süresinin bitimini takiben</td>
                    </tr>
                    <tr>
                        <td>
                            İşyeri Kişisel Sağlık Dosyası İçeriğindeki Veriler
                        </td>
                        <td>
                            Hizmet akdinin devamında ve hitamından itibaren 10
                            yıl müddetle saklanır
                        </td>
                        <td>180 gün içerisinde</td>
                    </tr>
                    <tr>
                        <td>İş sağlığı ve güvenliği uygulamaları</td>
                        <td>
                            İş ilişkisinin sona ermesine müteakip 10 yıl süre
                            ile saklanır.
                        </td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Personel ile ilgili mahkeme/icra bilgi taleplerinin
                            cevaplanması
                        </td>
                        <td>
                            İş ilişkisinin sona ermesine müteakip 10 yıl süre
                            ile saklanır.
                        </td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>Personel Finansman Süreçleri</td>
                        <td>
                            İş ilişkisinin sona ermesini müteakip 10 yıl süre
                            ile saklanır.
                        </td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>
                            İş Ortağı/Çözüm Ortağı/Danışman ile şirket
                            arasındaki ticari ilişkinin yürütümüne dair kimlik
                            bilgisi, iletişim bilgisi, finansal bilgiler, İş
                            Ortağı/ÇözümOrtağı/Danışman çalışanı verileri
                        </td>
                        <td>
                            Şirket ile olan iş/ticari ilişkisi süresince ve sona
                            ermesinden itibaren Türk Borçlar Kanunu md.146 ile
                            Türk Ticaret Kanunu md.82 uyarınca 10 yıl süre ile
                            saklanır.
                        </td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Fiziki mekanlara girişte alınan Ziyaretçi’ye ait ad,
                            soyad, araç plakası ile kamera kayıtları,
                        </td>
                        <td>2 yıl süre ile saklanır.</td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Çalışan Adayına ait özgeçmiş ve işe başvuru formunda
                            yer alan bilgiler
                        </td>
                        <td>
                            En fazla 2 yıl olmak üzere özgeçmişin güncelliğini
                            kaybedeceği süre kadar saklanır.
                        </td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>Stajyer’e ait staj dosyasında yer alan bilgiler</td>
                        <td>
                            Staj ilişkisinin devamında ve hitamını takip eden
                            takvim yılı yılbaşından itibaren de 10 yıl müddetle
                            muhafaza edilir.
                        </td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Müşteri’ye ait ad, soyad, T.C.K.N., iletişim
                            bilgileri, ödeme bilgileri ve yöntemleri,
                            ürün/hizmet tercihleri, işlem geçmişi,
                        </td>
                        <td>
                            Müşteri’nin, satın almış olduğu her bir
                            ürün/hizmetin sunulmasından itibaren Türk Borçlar
                            Kanunu md.146 ile Türk Ticaret Kanunu md.82 uyarınca
                            10 yıl süre ile saklanır.
                        </td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Potansiyel Müşteri ile şirket arasındaki ticari
                            ilişki kurulmasına dair sözleşme görüşmeleri
                            sırasında alınan kimlik bilgisi, iletişim bilgisi,
                            finansal bilgiler,
                        </td>
                        <td>2 yıl süre ile saklanır.</td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>
                            İşbirliği içinde olunan kurum,firmalar ve müşteriler
                            ile şirket arasındaki ticari ilişkinin yürütümüne
                            dair kimlik bilgisi, iletişim bilgisi, finansal
                            bilgiler, şirketin işbirliği içinde olduğu
                            kurum,firma, müşteri çalışanı verileri
                        </td>
                        <td>
                            Şirket ile olan iş/ticari ilişkisi süresince ve sona
                            ermesinden itibaren Türk Borçlar Kanunu md.146 ile
                            Türk Ticaret Kanunu md.82 uyarınca 10 yıl süre ile
                            saklanır.
                        </td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Kurumsal İletişim Faaliyetlerinin Planlanması ve
                            İcrası
                        </td>
                        <td>
                            İş ilişkisinin sona ermesine müteakip 10 yıl süre
                            ile saklanır.
                        </td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Bir Sözleşmenin Kurulması veya İfası İçin İşlenmesi
                            Gerekli Olan veya Bu Kapsamda İşlenen Diğer Veriler
                        </td>
                        <td>
                            Şirket ile iş/ticari ilişkisi süresince ve sona
                            ermesinden itibaren Türk Borçlar Kanunu md.146 ile
                            Türk Ticaret Kanunu md.82 uyarınca 10 yıl süre ile
                            saklanır.
                        </td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Şirket ortakları ve yönetim kurulu üyelerine ait
                            bilgiler
                        </td>
                        <td>10 yıl süre ile saklanır.</td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>Kaza Raporlama</td>
                        <td>10 yıl süre ile saklanır.</td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>Doküman hazırlanması</td>
                        <td>10 yıl süre ile saklanır.</td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                    <tr>
                        <td>Eğitim kayıtlarının dosyalanması</td>
                        <td>10 yıl süre ile saklanır.</td>
                        <td>
                            Saklama süresinin bitimini takiben 180 gün
                            içerisinde
                        </td>
                    </tr>
                </tbody>
            </table>
            <p>
                11-Kanun kapsamında kişisel verilerin saklanması için herhangi
                bir süre belirlenmemiş olmakla birlikte, genel ilkeler uyarınca
                kişisel verilerin ilgili mevzuatta öngörülen veya işlendikleri
                amaç için gerekli olan süre kadar muhafaza edilmesi esastır.
                Veri Sorumlusu Şirket, söz konusu ilkeye uygun bir şekilde
                saklama süreleri tespit etmek adına, her bir veri işleme süreci
                ile ilgili olarak yürürlükte bulunan mevzuatı ve sürecin amacını
                esas alarak bir değerlendirme yapmaktadır. Mevzuat uyarınca daha
                uzun bir süre düzenlenmiş olması ya da mevzuat uyarınca
                zamanaşımı, hak düşürücü süre, saklama süreleri vb. için daha
                uzun bir süre öngörülmüş olması halinde, mevzuat hükümlerindeki
                süreler azami saklama süresi olarak kabul edilir. Bu doğrultuda
                asgari olarak yasal yükümlülüklerinin gerektirdiği süre ve
                ilgili Kanuna konu zamanaşımı süreleri dolana kadar kişisel
                veriler saklanmaktadır.
            </p>
            <p>
                Kişisel veriler, Veri Sorumlusu ile aranızda doğabilecek
                herhangi bir uyuşmazlık durumunda, uyuşmazlık kapsamında gerekli
                savunmaların gerçekleştirilebilmesi amacıyla saklanabilecektir.
                Bahsi geçen sürelerin sona ermesi durumu da dahil olmak üzere
                herhangi bir süreç kapsamında ilgili kişisel verinin işleme
                amacının ortadan kalkması ile birlikte kişisel veriler Kanuna
                uygun bir şekilde anonimleştirilmekte, silinmekte veya yok
                edilmektedir.
            </p>
            <p>
                12- Saklama süresi sona eren veya saklama amacı ortadan kalkan
                kişisel veriler işbu Kişisel Verileri Saklama ve İmha
                Politikasında belirtilen ve tekrar eden aralıklarla re’sen
                gerçekleştirilecek bir işlemlerle altı ayda bir imha edilmek
                suretiyle silinir, yok edilir veya anonim hale getirilir.
                Periyodik imha işlemi her yılın Ocak ve Temmuz aylarında ayrıca
                gerçekleştirilir.
            </p>
            <p>
                13-Şirketimiz, KVK Kanunu’ndaki yükümlülükleri yerine getirmek
                ve işbu Politikada belirtilen hususların uygulanmasına yönelik
                olarak Şirket içerisinde gerekli görevlendirmeleri yapmakta ve
                buna uygun olarak prosedürleri oluşturmaktadır.
            </p>
            <p>
                14-Şirket faaliyetleri ve işlenen kişisel veri gruplarında
                olabilecek değişiklikler, yasal mevzuatta yapılacak değişikler
                ve Kişisel Verileri Koruma Kurulu ilke kararları takip edilerek,
                ortaya çıkan ihtiyaca göre işbu politika gözden geçirilir ve
                gerekli olan bölümler güncellenir, değiştirilir veya yeniden
                oluşturulur
            </p>
        </>
    );
};

export default KVKKKText;
