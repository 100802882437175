import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material";

const BasicDialog = ({
    open,
    onClose,
    icon,
    color,
    title,
    content,
    actions,
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle
                id="user-view-plans"
                sx={{
                    display: "flex",
                    gap: "8px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "1.25rem 1.25rem 0 1.25rem",
                    width: "35.625rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.625rem",
                    }}
                >
                    <Box
                        sx={{
                            width: "1.5rem",
                            height: "1.5rem",
                            "& svg": {
                                width: "1.5rem",
                                height: "1.5rem",
                                color: color ?? "text.firstTone",
                            },
                        }}
                    >
                        {icon ? icon : null}
                    </Box>
                    <Typography
                        variant="smallTitle"
                        sx={{
                            fontSize: "1.125rem",
                            fontWeight: "500",
                            color: "text.firstTone",
                        }}
                    >
                        {title}
                    </Typography>
                </Box>
            </DialogTitle>

            <DialogContent
                sx={{
                    width: "35.625rem",
                    p: "1rem 1.25rem !important",
                    maxHeight: "480px",
                }}
            >
                <DialogContentText
                    variant="body2"
                    id="user-view-plans-description"
                    sx={{
                        color: "text.thirdTone",
                    }}
                >
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{
                    p: "0 1.25rem 1.25rem 1.25rem",
                }}
            >
                {actions}
            </DialogActions>
        </Dialog>
    );
};

export default BasicDialog;
