import { useRef, useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputLabel,
    TextField,
    Box,
    useTheme,
} from "@mui/material";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";

import trLocale from "date-fns/locale/tr";
import ValidateEmail from "../../utils/validateEmail";
import ShowToast from "../ShowToast";
import InquiryTable from "../ApplicationInquiryDialog/InquiryTable";
import getOnlyDate from "../../utils/getOnlyDate";

const InquiryForm = () => {
    // Email
    const [Email, setEmail] = useState("");
    // BORN DATE
    const [bornDateValue, setBornDateValue] = useState(null);
    const [inquirySubmit, setInquirySubmit] = useState(false);
    const handleChangeBorn = (newValue) => {
        setBornDateValue(newValue);
    };
    const recaptchaRef = useRef();
    const [applicationData, setApplicationData] = useState(null);

    const onSubmit = () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        let config = {
            headers: {
                recaptcha: recaptchaValue,
            },
        };

        const data = {
            Email,
            BirthDate: getOnlyDate(bornDateValue),
        };

        if (!recaptchaValue)
            return ShowToast("Robot doğrulamayı tamamlamadınız!", {
                type: "error",
            });

        if (ValidateEmail(Email))
            axios
                .post("/common/application-form/user/check", data, config)
                .then((response) => {
                    ShowToast("Başvuru sorgulama başarılı!", {
                        type: "success",
                    });
                    setInquirySubmit(true);
                    setApplicationData(response.data.data);
                })
                .catch((error) => {
                    if (
                        error.response.data.message ==
                        "Robot doğrulama geçersiz"
                    )
                        recaptchaRef.current?.reset();

                    ShowToast(
                        error.response.data.message ??
                        "Başvurunuz sırasında bir hata oluştu!",
                        {
                            type: "error",
                        }
                    );
                });
        else
            ShowToast("Lütfen geçerli bir e-posta adresi giriniz.", {
                type: "error",
            });
    };
    const theme = useTheme();

    const useStyles = makeStyles(() => ({
        CardRoot: {
            minWidth: 275,
            borderTop: `10px solid ${theme.palette.secondary.main} !important`,
            backgroundColor: theme.palette.background.light,
            marginTop: "24px",
        },
        MuiInputBaseRoot: {
            "& .MuiInputBase-root": {
                backgroundColor: "white",
                border: `1px solid ${theme.palette.secondary.main}`,
            },
            "& .MuiFilledInput-multiline": {
                paddingTop: "0 !important",
            },
            "& .MuiFilledInput-input": {
                paddingTop: "0.75rem !important",
            },
            "& .MuiFilledInput-root": {
                paddingTop: "0 !important",
            },
        },
        BoxRoot: {
            backgroundColor: theme.palette.background.default,
            color: "primary.main",
            // bgcolor: "gainsboro",
            height: "100%",
        },
        GridItemRoot: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginBottom: "15px",
        },
    }));
    const classes = useStyles();

    return (
        <Box>
            <DialogTitle>Başvuru Sorgulama Ekranı</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Daha önce yapmış olduğunuz başvuruyu bu ekran üzerinden
                    sorgulayabilirsiniz.
                </DialogContentText>

                <Grid
                    container
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                        gap: "1.25rem",
                    }}
                >
                    <Grid item xs={12} sm={12}>
                        <InputLabel
                            sx={{
                                ml: "0.5rem",
                                mb: "0.25rem",
                                fontSize: "0.82rem",
                            }}
                            htmlFor="email-inquiry"
                        >
                            E-Posta Adresi
                        </InputLabel>
                        <TextField
                            className={classes.MuiInputBaseRoot}
                            required
                            id="email-inquiry"
                            // label="E-Posta Adresi"
                            variant="filled"
                            fullWidth
                            value={Email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel
                            sx={{
                                ml: "0.5rem",
                                mb: "0.25rem",
                                fontSize: "0.82rem",
                            }}
                            htmlFor="birth-date-inquiry"
                        >
                            Doğum Tarihi
                        </InputLabel>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={trLocale}
                            timeZone="Europe/Istanbul"
                        >
                            <MobileDatePicker
                                value={bornDateValue}
                                onChange={handleChangeBorn}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="birth-date-inquiry"
                                        className={classes.MuiInputBaseRoot}
                                        required
                                        variant="filled"
                                        fullWidth
                                        sx={{
                                            "& .MuiFilledInput-root": {
                                                paddingTop: "0 !important",
                                            },
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LdVY1AfAAAAADz7Wfi90us82U2JJtEHFEtb8H47"
                            hl="tr"
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <InquiryTable
                        data={applicationData}
                        inquirySubmit={inquirySubmit}
                    />
                </Grid>
            </DialogContent>
            <DialogActions sx={{ p: "1.25rem", justifyContent: "center" }}>
                <Button variant="contained" onClick={onSubmit}>
                    Başvuru Sorgula
                </Button>
            </DialogActions>
        </Box>
    );
};

export default InquiryForm;
