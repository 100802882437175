import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import formatDate from "../../../utils/formatDate";

const InquiryTable = ({ data, inquirySubmit }) => {
    const tableHeaders = [
        {
            id: "period",
            label: "Dönem",
            sx: { borderRadius: "6px 0 0 6px !important", borderBottom: 0 },
        },
        {
            id: "created_at",
            label: "Başvuru Tarihi",
            sx: { borderBottom: 0 },
        },
        {
            id: "status",
            label: "Durum",
            sx: { borderRadius: "0 6px 6px 0 !important", borderBottom: 0 },
        },
    ];

    const statuses = [
        { name: "Bekliyor", value: "pending" },
        { name: "Reddedildi", value: "rejected" },
        { name: "Onaylandı", value: "approved" },
    ];

    if (!inquirySubmit) return null;

    return (
        <Table sx={{ mt: "1.25rem" }}>
            <TableHead
                sx={{
                    backgroundColor: "background.default",
                    borderRadius: "6px 6px 0 0",
                }}
            >
                <TableRow>
                    {tableHeaders.map((header) => (
                        <TableCell key={header.id} sx={header.sx}>
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: 500,
                                    color: "text.primary",
                                    borderRadius: "6px !important",
                                }}
                            >
                                {header.label}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {!data?.length ? (
                    <TableRow>
                        <TableCell colSpan={6}>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                }}
                            >
                                Herhangi bir başvuru bulunamadı.
                            </Typography>
                        </TableCell>
                    </TableRow>
                ) : (
                    data?.map((row) => {
                        return (
                            <TableRow key={row}>
                                <TableCell>
                                    <Typography>{row.PeriodName}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {formatDate(row.CreatedAt)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        sx={{ textTransform: "capitalize" }}
                                    >
                                        {statuses.find(
                                            (status) =>
                                                status.value == row.Status
                                        )?.name || ""}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        );
                    })
                )}
            </TableBody>
        </Table>
    );
};

export default InquiryTable;
