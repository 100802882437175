import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    overrides: {
        MuiButton: {
            root: {
                borderRadius: "6px", // Tüm butonların köşe yarıçapı
            },
        },
    },
    palette: {
        primary: {
            main: "#005CA9", // Ana renk
        },
        error: {
            main: "#E30613", // Hata rengi
        },
        background: {
            default: "#E6E7EA", // Arka plan rengi
            main: "#06102A",
            light: "#fafafa",
        },
        secondary: {
            main: "#384055", // İkincil renk (isteğe bağlı olarak değiştirilebilir)
        },

        text: {
            primary: "#333333", // Metin rengi
            secondary: "#666666", // İkincil metin rengi
        },
    },
});

export default theme;
