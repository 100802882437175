import { useEffect, useRef, useState } from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputMask from "react-input-mask";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import trLocale from "date-fns/locale/tr";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useTheme, styled, Tooltip } from "@mui/material";
import ApplicationInquiryDialog from "./ApplicationInquiryDialog";
import cities from "../utils/cityList";
import ShowToast from "./ShowToast";
import InquiryForm from "./InquiryForm";
import getOnlyDate from "../utils/getOnlyDate";
import KVKKCheckbox from "./KVKKCheckbox";

let optionsUniversities = [];
let optionsFaculties = [{ label: "Üniversite Seçiniz", value: "-1" }];
let optionsDepartments = [{ label: "Fakülte Seçiniz", value: "-1" }];

export default function ApplicationForm({ periodDetails, setPeriodDetails }) {
    const theme = useTheme();
    const useStyles = makeStyles(() => ({
        CardRoot: {
            minWidth: 275,
            borderTop: `10px solid ${theme.palette.secondary.main} !important`,
            backgroundColor: theme.palette.background.light,
            marginTop: "24px",
        },
        MuiInputBaseRoot: {
            "& .MuiInputBase-root": {
                backgroundColor: "white",
                border: `1px solid ${theme.palette.secondary.main}`,
            },
            "& .MuiFilledInput-multiline": {
                paddingTop: "0.75rem !important",
            },
            "& .MuiFilledInput-input": {
                paddingTop: "0.75rem !important",
            },
            "& .MuiFilledInput-root": {
                paddingTop: "0 !important",
            },
        },
        BoxRoot: {
            backgroundColor: theme.palette.background.default,
            color: "primary.main",
            // bgcolor: "gainsboro",
            height: "100%",
        },
        GridItemRoot: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginBottom: "15px",
        },
    }));

    const classes = useStyles();
    const recaptchaRef = useRef();

    const [universityValue, setUniversityValue] = useState("");
    const [universityInputValue, setUniversityInputValue] = useState("");
    const [facultyValue, setFacultyValue] = useState("");
    const [facultyInputValue, setFacultyInputValue] = useState("");
    const [departmentValue, setDepartmentValue] = useState("");
    const [departmentInputValue, setDepartmentInputValue] = useState("");
    const [openInquiryDialog, setOpenInquiryDialog] = useState(false);
    const [error, setError] = useState(false);

    // PHONE
    const [phone, setPhone] = useState();
    const handleChangePhone = (event) => {
        setPhone(event.target.value);
    };
    // PHONE

    // BORN DATE
    const [bornDateValue, setBornDateValue] = useState(null);
    const handleChangeBorn = (newValue) => {
        setBornDateValue(newValue);
    };
    // BORN DATE

    // GENDER
    const [gender, setGenderValue] = useState("");
    const handleChangeGender = (event) => {
        setGenderValue(event.target.value);
    };
    // GENDER

    // CITY
    const [city, setCityValue] = useState("");
    const handleChangeCity = (event) => {
        setCityValue(event.target.value);
    };
    // CITY

    // GRADE
    const [grade, setGradeValue] = useState(0);
    const handleChangeGrade = (event) => {
        setGradeValue(event.target.value);
    };
    // GRADE

    const inviteID = window.location.pathname.split("/application-form")[1];

    useEffect(() => {
        let inviteID = window.location.href.split("/").pop();
        axios
            .get(`/common/application-form/${inviteID}`)
            .then((response) => {
                setPeriodDetails(response.data.data);
            })
            .catch((error) => {
                setPeriodDetails({ error });

                if (error.response.data.message == "Geçersiz form bağlantısı.")
                    setError(true);
            });

        axios
            .get("/common/university/list")
            .then((response) => {
                let universities = response.data.data;
                universities.forEach((university, index) => {
                    optionsUniversities[index] = {
                        label: university.Name,
                        value: university.ID,
                    };
                });
            })
            .catch((error) => {
                // console.log("error", error);
                // console.log("error.response", error.response);
            });
    }, []);

    useEffect(() => {
        optionsFaculties = [];
        if (
            universityValue != null &&
            universityValue !== undefined &&
            universityValue !== ""
        ) {
            axios
                .get("/common/university/faculty/list/" + universityValue.value)
                .then((response) => {
                    let faculties = response.data.data;
                    faculties.forEach((faculty, index) => {
                        optionsFaculties[index] = {
                            label: faculty.Name,
                            value: faculty.ID,
                        };
                    });
                    setFacultyValue(optionsFaculties[0]);
                    setFacultyID(optionsFaculties[0]?.value);
                })
                .catch((error) => {
                    // console.log("error", error);
                    // console.log("error.response", error.response);
                });
        } else {
            optionsFaculties = [
                { label: "Önce Üniversite Seçiniz", value: "-1" },
            ];
            setFacultyValue(optionsFaculties[0]);
        }
    }, [universityValue]);

    useEffect(() => {
        optionsDepartments = [];
        if (
            facultyValue != null &&
            facultyValue !== undefined &&
            facultyValue !== "" &&
            facultyValue?.value != -1
        ) {
            axios
                .get("/common/university/department/list/" + facultyValue.value)
                .then((response) => {
                    let departments = response.data.data;
                    departments.forEach((department, index) => {
                        optionsDepartments[index] = {
                            label: department.Name,
                            value: department.ID,
                        };
                    });
                    setDepartmentValue(optionsDepartments[0]);
                    setDepartmentID(optionsDepartments[0]?.value);
                })
                .catch((error) => { });
        } else {
            optionsDepartments = [
                { label: "Önce Fakülte Seçiniz", value: "-1" },
            ];
            setDepartmentValue(optionsDepartments[0]);
        }
    }, [facultyValue]);

    const [kvkkChecked, setKvkkChecked] = useState(false);
    const [IdentityNumber, setIdentityNumber] = useState(null);
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [About, setAbout] = useState("");
    const [WorkExperience, setWorkExperience] = useState("");
    const [Projects, setProjects] = useState("");
    const [Hobbies, setHobbies] = useState("");
    const [SocialFacebookUrl, setSocialFacebookUrl] = useState("");
    const [SocialInstagramUrl, setSocialInstagramUrl] = useState("");
    const [SocialTwitterUrl, setSocialTwitterUrl] = useState("");
    const [SocialLinkedinUrl, setSocialLinkedinUrl] = useState("");
    const [SocialYoutubeUrl, setSocialYoutubeUrl] = useState("");
    const [SocialGithubUrl, setSocialGithubUrl] = useState("");
    const [SocialOthersUrl, setSocialOthersUrl] = useState("");
    const [TechnicalSkills, setTechnicalSkills] = useState("");
    const [Responsibility, setResponsibility] = useState("");
    const [UniversityID, setUniversityID] = useState(0);
    const [DepartmentID, setDepartmentID] = useState(0);
    const [FacultyID, setFacultyID] = useState(0);

    function ValidateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    }

    function ValidatePhone(phone) {
        if (phone === undefined) return false;
        let clearedPhone = phone;
        clearedPhone = clearedPhone.replaceAll("_", "");
        clearedPhone = clearedPhone.replaceAll("(", "");
        clearedPhone = clearedPhone.replaceAll(")", "");
        clearedPhone = clearedPhone.replaceAll(" ", "");
        if (/^\d{10}$/.test(clearedPhone)) {
            return true;
        }
        return false;
    }

    const ValidateResponsibility = (responsibility) => {
        // "okudum", "anladım" ve "kabul ediyorum" ifadelerini içeren regex deseni
        var pattern = /okudum|anladım|kabul ediyorum/ig;

        // Türkçe karakterleri de içerecek şekilde küçük harfe çevirme
        let control_responsibility = responsibility.replace(/İ/g, "i");

        // Deseni kullanarak eşleşme kontrolü
        return (pattern.test(control_responsibility.toLowerCase()) ? true : false);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        const recaptchaValue = recaptchaRef.current.getValue();

        if (!recaptchaValue) {
            return ShowToast("Robot doğrulamayı tamamlamadınız!", {
                type: "error",
            });
        }
        if (!IdentityNumber || IdentityNumber.length != 11) {
            return ShowToast("Lütfen geçerli bir TC Kimlik No giriniz!", {
                type: "error",
            });
        }
        if (!Name || Name.length < 5) {
            return ShowToast("Ad ve soyad boş geçilemez!", {
                type: "error",
            });
        }
        if (!Email || ValidateEmail(Email) === false) {
            return ShowToast("E-posta adresi boş geçilemez!", {
                type: "error",
            });
        }
        if (!phone || ValidatePhone(phone) === false) {
            return ShowToast("Telefon numarası boş geçilemez!", {
                type: "error",
            });
        }
        if (!gender || (gender !== "male" && gender !== "female")) {
            return ShowToast("Cinsiyet boş geçilemez!", {
                type: "error",
            });
        }
        if (!city || city <= 0 || city >= 82) {
            return ShowToast("İl boş geçilemez!", {
                type: "error",
            });
        }
        if (!Responsibility || !ValidateResponsibility(Responsibility)) {
            return ShowToast("Sorumluluk beyanı boş geçilemez!", {
                type: "error",
            });
        }
        if (!getOnlyDate(bornDateValue)) {
            return ShowToast("Doğum tarihi boş geçilemez!", {
                type: "error",
            });
        }
        if (!UniversityID || UniversityID <= 0) {
            return ShowToast("Üniversite boş geçilemez!", {
                type: "error",
            });
        }
        if (!FacultyID || FacultyID <= 0) {
            return ShowToast("Fakülte boş geçilemez!", {
                type: "error",
            });
        }
        if (!DepartmentID || DepartmentID <= 0) {
            return ShowToast("Bölüm boş geçilemez!", {
                type: "error",
            });
        }
        if (!grade || grade <= 0) {
            return ShowToast("Sınıf boş geçilemez!", {
                type: "error",
            });
        }
        if (!kvkkChecked) {
            return ShowToast("KVKK onayı boş geçilemez!", {
                type: "error",
            });
        }
        if (!About || About.length < 3) {
            return ShowToast("Hakkımda boş geçilemez!", {
                type: "error",
            });
        }
        if (!WorkExperience || WorkExperience.length < 3) {
            return ShowToast("İş deneyimlerim boş geçilemez!", {
                type: "error",
            });
        }
        if (!TechnicalSkills || TechnicalSkills.length < 3) {
            return ShowToast("Teknik becerilerim boş geçilemez!", {
                type: "error",
            });
        }
        if (!Projects || Projects.length < 3) {
            return ShowToast("Projelerim boş geçilemez!", {
                type: "error",
            });
        }
        if (!Hobbies || Hobbies.length < 3) {
            return ShowToast("Hobilerim boş geçilemez!", {
                type: "error",
            });
        }
        // if (!SocialFacebookUrl || SocialFacebookUrl.length < 3) {
        //     return ShowToast("Facebook URL boş geçilemez!", {
        //         type: "error",
        //     });
        // }
        // if (!SocialInstagramUrl || SocialInstagramUrl.length < 3) {
        //     return ShowToast("Instagram URL boş geçilemez!", {
        //         type: "error",
        //     });
        // }
        // if (!SocialTwitterUrl || SocialTwitterUrl.length < 3) {
        //     return ShowToast("Twitter URL boş geçilemez!", {
        //         type: "error",
        //     });
        // }
        // if (!SocialLinkedinUrl || SocialLinkedinUrl.length < 3) {
        //     return ShowToast("Linkedin URL boş geçilemez!", {
        //         type: "error",
        //     });
        // }
        // if (!SocialYoutubeUrl || SocialYoutubeUrl.length < 3) {
        //     return ShowToast("Youtube URL boş geçilemez!", {
        //         type: "error",
        //     });
        // }
        // if (!SocialGithubUrl || SocialGithubUrl.length < 3) {
        //     return ShowToast("Github URL boş geçilemez!", {
        //         type: "error",
        //     });
        // }

        const data = {
            IdentityNumber,
            Name,
            Email,
            PhoneNumber: phone,
            BirthDate: getOnlyDate(bornDateValue),
            Gender: gender,
            CityID: city,
            UniversityID,
            FacultyID,
            DepartmentID,
            ClassID: grade,
            About,
            WorkExperience,
            TechnicalSkills,
            Projects,
            Hobbies,
            SocialFacebookUrl,
            SocialInstagramUrl,
            SocialTwitterUrl,
            SocialLinkedinUrl,
            SocialYoutubeUrl,
            SocialGithubUrl,
            SocialOtherUrl: SocialOthersUrl,
            Responsibility,
        };

        let config = {
            headers: {
                recaptcha: recaptchaValue,
            },
        };

        let inviteID = window.location.href.split("/").pop();

        axios
            .post(`/common/application-form/${inviteID}`, data, config)
            .then((response) => {
                ShowToast("Başvurunuz başarılı bir şekilde alındı!", {
                    type: "success",
                });
            })
            .catch((error) => {
                if (error.response.data.message == "Robot doğrulama geçersiz")
                    recaptchaRef.current?.reset();

                ShowToast(
                    error.response.data.message ??
                    "Başvurunuz sırasında bir hata oluştu!",
                    {
                        type: "error",
                    }
                );
            });
    };

    const grades = [
        "1.Sınıf",
        "2.Sınıf",
        "3.Sınıf",
        "4.Sınıf",
        "5.Sınıf",
        "6.Sınıf",
        "Hazırlık",
        "Mezun",
        "Yüksek Lisans",
        "Doktora",
    ];

    return (
        <Box className={classes.BoxRoot}>
            <Container sx={{ padding: "20px" }}>
                <Box>
                    {!periodDetails?.error ? (
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            onSubmit={(e) => onSubmit(e)}
                        >
                            <Card
                                variant="outlined"
                                className={classes.CardRoot}
                            >
                                <StyledCardContent
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexWrap: "wrap",
                                        gap: "1rem",
                                    }}
                                >
                                    <Button
                                        sx={{
                                            paddingInline: "2rem",
                                        }}
                                        variant="contained"
                                        onClick={() =>
                                            setOpenInquiryDialog(true)
                                        }
                                    >
                                        Başvuru Sorgula
                                    </Button>
                                    <ApplicationInquiryDialog
                                        open={openInquiryDialog}
                                        handleClose={() => {
                                            setOpenInquiryDialog(false);
                                        }}
                                        classes={classes}
                                    />
                                </StyledCardContent>
                            </Card>

                            <Card
                                variant="outlined"
                                className={classes.CardRoot}
                            >
                                <StyledCardContent sx={{ p: "1.5rem" }}>
                                    <Typography variant="h4" component="div">
                                        Kişisel Bilgiler
                                    </Typography>
                                </StyledCardContent>
                                <StyledCardContent sx={{ p: "1.5rem" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="IdentityNumber"
                                            >
                                                TC Kimlik No *
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                required
                                                type="number"
                                                id="IdentityNumber"
                                                variant="filled"
                                                fullWidth
                                                value={IdentityNumber}
                                                onChange={(e) =>
                                                    setIdentityNumber(
                                                        e.target.value
                                                    )
                                                }
                                                onInput={(e) => {
                                                    e.target.value = Math.max(
                                                        0,
                                                        parseInt(e.target.value)
                                                    )
                                                        .toString()
                                                        .slice(0, 11);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="NameSurname"
                                            >
                                                Ad Soyad *
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                required
                                                id="NameSurname"
                                                variant="filled"
                                                fullWidth
                                                value={Name}
                                                onChange={(e) =>
                                                    setName(e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="Email"
                                            >
                                                E-Posta Adresi *
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                required
                                                id="Email"
                                                variant="filled"
                                                fullWidth
                                                value={Email}
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="Telefon Numarası"
                                            >
                                                Telefon Numarası *
                                            </InputLabel>
                                            <InputMask
                                                mask="(999) 999 99 99"
                                                value={phone}
                                                onChange={(e) =>
                                                    handleChangePhone(e)
                                                }
                                            >
                                                {() => (
                                                    <TextField
                                                        className={
                                                            classes.MuiInputBaseRoot
                                                        }
                                                        id="Telefon Numarası"
                                                        required
                                                        variant="filled"
                                                        fullWidth
                                                    />
                                                )}
                                            </InputMask>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="birth-date"
                                            >
                                                Doğum Tarihi *
                                            </InputLabel>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                                locale={trLocale}
                                            >
                                                <MobileDatePicker
                                                    // label="Doğum Tarihi"
                                                    value={bornDateValue}
                                                    onChange={handleChangeBorn}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            id="birth-date"
                                                            className={
                                                                classes.MuiInputBaseRoot
                                                            }
                                                            required
                                                            variant="filled"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                id="gender-label"
                                            >
                                                Cinsiyet *
                                            </InputLabel>
                                            <FormControl
                                                variant="filled"
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                fullWidth
                                            >
                                                <Select
                                                    labelId="gender-label"
                                                    id="gender"
                                                    value={gender}
                                                    onChange={
                                                        handleChangeGender
                                                    }
                                                    required
                                                >
                                                    <MenuItem value={"female"}>
                                                        Kadın
                                                    </MenuItem>
                                                    <MenuItem value={"male"}>
                                                        Erkek
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                id="city-label"
                                            >
                                                Yaşadığı İl *
                                            </InputLabel>
                                            <FormControl
                                                variant="filled"
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                fullWidth
                                            >
                                                <Select
                                                    labelId="city-label"
                                                    id="city"
                                                    value={city}
                                                    onChange={handleChangeCity}
                                                >
                                                    {cities.map(
                                                        (city, index) => (
                                                            <MenuItem
                                                                key={city}
                                                                value={
                                                                    index + 1
                                                                }
                                                            >
                                                                {city}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </StyledCardContent>
                            </Card>

                            <Card
                                variant="outlined"
                                className={classes.CardRoot}
                            >
                                <StyledCardContent>
                                    <Typography variant="h4" component="div">
                                        Okul Bilgileri
                                    </Typography>
                                </StyledCardContent>

                                <StyledCardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="university"
                                            >
                                                Üniversite
                                            </InputLabel>
                                            <Autocomplete
                                                value={universityValue}
                                                onChange={(event, newValue) => {
                                                    setUniversityValue(
                                                        newValue
                                                    );
                                                    setUniversityID(
                                                        newValue.value
                                                    );
                                                }}
                                                inputValue={
                                                    universityInputValue
                                                }
                                                onInputChange={(
                                                    event,
                                                    newInputValue
                                                ) => {
                                                    setUniversityInputValue(
                                                        newInputValue
                                                    );
                                                }}
                                                id="university"
                                                options={optionsUniversities}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className={
                                                            classes.MuiInputBaseRoot
                                                        }
                                                        id="University"
                                                        variant="filled"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="faculty"
                                            >
                                                Fakülte
                                            </InputLabel>
                                            <Autocomplete
                                                value={facultyValue}
                                                onChange={(event, newValue) => {
                                                    setFacultyValue(newValue);
                                                    setFacultyID(
                                                        newValue.value
                                                    );
                                                }}
                                                inputValue={facultyInputValue}
                                                onInputChange={(
                                                    event,
                                                    newInputValue
                                                ) => {
                                                    setFacultyInputValue(
                                                        newInputValue
                                                    );
                                                }}
                                                id="faculty"
                                                options={optionsFaculties}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className={
                                                            classes.MuiInputBaseRoot
                                                        }
                                                        id="Faculty"
                                                        variant="filled"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="department"
                                            >
                                                Bölüm
                                            </InputLabel>
                                            <Autocomplete
                                                value={departmentValue}
                                                onChange={(event, newValue) => {
                                                    setDepartmentValue(
                                                        newValue
                                                    );
                                                    setDepartmentID(
                                                        newValue.value
                                                    );
                                                }}
                                                inputValue={
                                                    departmentInputValue
                                                }
                                                onInputChange={(
                                                    event,
                                                    newInputValue
                                                ) => {
                                                    setDepartmentInputValue(
                                                        newInputValue
                                                    );
                                                }}
                                                id="department"
                                                options={optionsDepartments}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        id="Department"
                                                        variant="filled"
                                                        fullWidth
                                                        className={
                                                            classes.MuiInputBaseRoot
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                id="grade-label"
                                            >
                                                Sınıf
                                            </InputLabel>
                                            <FormControl
                                                variant="filled"
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                fullWidth
                                            >
                                                <Select
                                                    labelId="grade-label"
                                                    id="grade"
                                                    value={grade}
                                                    onChange={handleChangeGrade}
                                                    sx={{
                                                        "& .MuiFilledInput-input":
                                                        {
                                                            pt: "7px !important",
                                                        },
                                                        "&.MuiFilledInput-root":
                                                        {
                                                            pt: "0px !important",
                                                        },
                                                    }}
                                                >
                                                    {grades.map((grade, i) => (
                                                        <MenuItem
                                                            key={grade}
                                                            value={i + 1}
                                                        >
                                                            {grade}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </StyledCardContent>
                            </Card>

                            <Card
                                variant="outlined"
                                className={classes.CardRoot}
                            >
                                <StyledCardContent>
                                    <Typography variant="h4" component="div">
                                        Yetkinlik Bilgileri
                                    </Typography>
                                </StyledCardContent>

                                <StyledCardContent>
                                    <Grid container spacing={2}>
                                        {/* <Grid
                                        container
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    > */}
                                        <Grid item xs={12} sm={12}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="About"
                                            >
                                                Hakkında
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                id="About"
                                                // label="Hakkında"
                                                variant="filled"
                                                multiline
                                                rows={5}
                                                fullWidth
                                                value={About}
                                                onChange={(e) => {
                                                    setAbout(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                        {/* </Grid> */}
                                        {/* <Grid
                                        container
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 20,
                                        }}
                                    > */}
                                        <Grid item xs={12} sm={12}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="JobExperience"
                                            >
                                                İş Deneyimi
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                id="JobExperience"
                                                // label="İş Deneyimi"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={5}
                                                value={WorkExperience}
                                                onChange={(e) => {
                                                    setWorkExperience(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        {/* </Grid> */}
                                        {/* <Grid
                                        container
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 20,
                                        }}
                                    > */}
                                        <Grid item xs={12} sm={12}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="TechnicalSkills"
                                            >
                                                Teknik Beceriler
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                id="TechnicalSkills"
                                                // label="Teknik Beceriler"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={5}
                                                value={TechnicalSkills}
                                                onChange={(e) => {
                                                    setTechnicalSkills(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        {/* </Grid> */}
                                        {/* <Grid
                                        container
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 20,
                                        }}
                                    > */}
                                        <Grid item xs={12} sm={12}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="Projects"
                                            >
                                                Projeler
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                id="Projects"
                                                // label="Projeler"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={5}
                                                value={Projects}
                                                onChange={(e) => {
                                                    setProjects(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                        {/* </Grid> */}

                                        {/* <Grid
                                        container
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 20,
                                        }}
                                    > */}
                                        <Grid item xs={12} sm={12}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="Hobbies"
                                            >
                                                Hobiler
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                id="Hobbies"
                                                // label="Hobiler"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={5}
                                                value={Hobbies}
                                                onChange={(e) => {
                                                    setHobbies(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                        {/* </Grid> */}
                                    </Grid>
                                </StyledCardContent>
                            </Card>

                            <Card
                                variant="outlined"
                                className={classes.CardRoot}
                            >
                                <StyledCardContent>
                                    <Typography variant="h4" component="div">
                                        Sosyal Medya Bilgileri
                                    </Typography>
                                </StyledCardContent>

                                <StyledCardContent>
                                    <Grid container spacing={2}>
                                        {/* <Grid
                                        container
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    > */}
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="FacebookURL"
                                            >
                                                Facebook URL
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                id="FacebookURL"
                                                // label="Facebook URL"
                                                variant="filled"
                                                fullWidth
                                                value={SocialFacebookUrl}
                                                onChange={(e) => {
                                                    setSocialFacebookUrl(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        {/* </Grid> */}
                                        {/* <Grid
                                        container
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 20,
                                        }}
                                    > */}
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="TwitterURL"
                                            >
                                                Twitter URL
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                id="TwitterURL"
                                                // label="Twitter URL"
                                                variant="filled"
                                                fullWidth
                                                value={SocialTwitterUrl}
                                                onChange={(e) => {
                                                    setSocialTwitterUrl(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        {/* </Grid> */}
                                        {/* <Grid
                                        container
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 20,
                                        }}
                                    > */}
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="InstagramURL"
                                            >
                                                Instagram URL
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                id="InstagramURL"
                                                // label="Instagram URL"
                                                variant="filled"
                                                fullWidth
                                                value={SocialInstagramUrl}
                                                onChange={(e) => {
                                                    setSocialInstagramUrl(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        {/* </Grid> */}
                                        {/* <Grid
                                        container
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 20,
                                        }}
                                    > */}
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="LinkedInURL"
                                            >
                                                LinkedIn URL
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                id="LinkedInURL"
                                                // label="LinkedIn URL"
                                                variant="filled"
                                                fullWidth
                                                value={SocialLinkedinUrl}
                                                onChange={(e) => {
                                                    setSocialLinkedinUrl(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        {/* </Grid> */}
                                        {/* <Grid
                                        container
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 20,
                                        }}
                                    > */}
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="YoutubeURL"
                                            >
                                                YouTube URL
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                id="YoutubeURL"
                                                // label="Youtube URL"
                                                variant="filled"
                                                fullWidth
                                                value={SocialYoutubeUrl}
                                                onChange={(e) => {
                                                    setSocialYoutubeUrl(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        {/* </Grid> */}
                                        {/* <Grid
                                        container
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 20,
                                        }}
                                    > */}
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="GithubURL"
                                            >
                                                GitHub URL
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                id="GithubURL"
                                                // label="Github URL"
                                                variant="filled"
                                                fullWidth
                                                value={SocialGithubUrl}
                                                onChange={(e) => {
                                                    setSocialGithubUrl(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        {/* </Grid> */}
                                        {/* <Grid
                                        container
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 20,
                                        }}
                                    > */}
                                        <Grid item xs={12} sm={12}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="OthersURL"
                                            >
                                                Diğer
                                            </InputLabel>
                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                id="OthersURL"
                                                // label="Diğer"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={5}
                                                value={SocialOthersUrl}
                                                onChange={(e) => {
                                                    setSocialOthersUrl(
                                                        e.target.value
                                                    );
                                                }}
                                                sx={{
                                                    maxHeight: "3.5rem",
                                                }}
                                            />
                                        </Grid>
                                        {/* </Grid> */}
                                    </Grid>
                                </StyledCardContent>
                            </Card>

                            <Card
                                variant="outlined"
                                className={classes.CardRoot}
                            >
                                <StyledCardContent>
                                    <Typography variant="h4" component="div">
                                        Sorumluluk Taahhütnamesi
                                    </Typography>
                                </StyledCardContent>

                                <StyledCardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="body1" component="div">
                                                Başvurumun kabul edilmesi durumunda, Siber Vatan programı kapsamında aldığım eğitimler süresince ve sonrasında edindiğim tüm teknik bilgi ve becerileri sadece etik ve yasal çerçeveler içinde kullanacağımı taahhüt ederim. Bu bilgilerin kötüye kullanımını - gerçek sistemlere yetkisiz erişim sağlama, yasa dışı aktivitelerde bulunma veya zararlı yazılım oluşturma ve yayma gibi - açıkça reddederim. Bu tür uygunsuz davranışların yasal yaptırımlara ve ciddi etik ihlallere yol açacağının farkındayım ve bu tür eylemlerden kaçınacağımı beyan ederim.
                                                <br />
                                                <br />
                                                Ayrıca, bu eğitimler kapsamında edindiğim tüm bilgi, belge ve materyallerin gizliliğini korumayı taahhüt ederim. Bu bilgi ve materyalleri hiçbir üçüncü şahıs ile paylaşmayacağımı, yetkisiz kişilerin erişimini veya ele geçirilmesini önlemek amacıyla gerekli tüm güvenlik önlemlerini alacağımı kabul ederim. Bu önlemler dijital güvenlik uygulamalarını ve fiziksel güvenlik tedbirlerini kapsar. Eğitim kapsamında edindiğim bilgi, belge ve materyallerin yetkisiz kullanımı ya da paylaşımı durumunda doğabilecek herhangi bir zarar veya hukuki sonuçtan bireysel olarak sorumlu olacağımı beyan ederim.
                                                <br />
                                                <br />
                                                Bu taahhütname ile, eğitimlerde edinilen bilgi ve becerilerin kötüye kullanılması sonucunda ortaya çıkabilecek tüm hukuki sonuçlardan tamamen sorumlu olduğumu beyan ve taahhüt ederim. Edinilen bilgi ve becerilerin etik olmayan, yasa dışı veya zarar verici şekillerde kullanılmasından doğacak herhangi bir hukuki yaptırım veya cezai sorumluluğun bireysel olarak üzerimde olacağını kabul ederim ve bu konuda eğitim veren kurumu sorumlu tutamayacağımı açıkça belirtirim.
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <InputLabel
                                                sx={{
                                                    ml: "0.5rem",
                                                    mb: "0.25rem",
                                                    fontSize: "0.82rem",
                                                }}
                                                htmlFor="Responsibility"
                                            >
                                                Aşağıya <b>"OKUDUM, ANLADIM VE KABUL EDİYORUM"</b> yazınız
                                            </InputLabel>

                                            <TextField
                                                className={
                                                    classes.MuiInputBaseRoot
                                                }
                                                id="Responsibility"
                                                // label="Facebook URL"
                                                variant="filled"
                                                fullWidth
                                                value={Responsibility}
                                                onChange={(e) => {
                                                    setResponsibility(
                                                        e.target.value
                                                    );
                                                }}
                                                // onPaste={(e) => {
                                                //     e.preventDefault();
                                                //     setResponsibility("")

                                                //     ShowToast("Kendiniz yazmalısınız. Kopyalayamazsınız.", {
                                                //         type: "error",
                                                //     })
                                                // }}
                                            />
                                        </Grid>
                                    </Grid>
                                </StyledCardContent>
                            </Card>

                            <Grid
                                container
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: 20,
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    className={classes.GridItemRoot}
                                >
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LdVY1AfAAAAADz7Wfi90us82U2JJtEHFEtb8H47"
                                        hl="tr"
                                    />
                                </Grid>

                                <KVKKCheckbox
                                    kvkkChecked={kvkkChecked}
                                    setKvkkChecked={setKvkkChecked}
                                />

                                <Grid item xs={12} sm={6}>
                                    <Tooltip title={
                                        !kvkkChecked ? "Lütfen KVKK onaylayınız" : !ValidateResponsibility(Responsibility) ? "Lütfen Sorumluluk Taahhütnamesini onaylayınız" : ""
                                    }>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            disabled={kvkkChecked == false || ValidateResponsibility(Responsibility) == false}
                                        >
                                            Başvur
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>
                    ) : error && inviteID ? (
                        <Card
                            sx={{
                                maxWidth: "70%",
                                margin: "auto",
                            }}
                            variant="outlined"
                            className={classes.CardRoot}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    mt: "1.25rem",
                                }}
                            >
                                <StyledCardContent>
                                    <Typography
                                        variant="h4"
                                        component="div"
                                        sx={{ textAlign: "center" }}
                                    >
                                        Geçersiz Form Bağlantısı
                                    </Typography>
                                </StyledCardContent>

                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        mb: "1.25rem",
                                    }}
                                >
                                    <Button
                                        sx={{
                                            paddingInline: "2rem",
                                        }}
                                        variant="contained"
                                        onClick={() =>
                                            setOpenInquiryDialog(true)
                                        }
                                    >
                                        Başvuru Sorgula
                                    </Button>
                                </Box>
                                <ApplicationInquiryDialog
                                    open={openInquiryDialog}
                                    handleClose={() => {
                                        setOpenInquiryDialog(false);
                                    }}
                                    classes={classes}
                                />
                            </Box>
                        </Card>
                    ) : (
                        <InquiryForm />
                    )}
                </Box>
            </Container>
        </Box>
    );
}

const StyledCardContent = styled(CardContent)`
    padding: 1.5rem;
`;
