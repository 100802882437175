import { toast } from "react-toastify";

const ShowToast = (msg, options) => {
    const defaultOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // type: "success",
        ...options,
    };

    return toast(msg, defaultOptions);
};

export default ShowToast;
